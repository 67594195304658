import axios from "axios";
import {server, userId} from "../environment";

class FileUploadService {
  upload(file, fileName, onUploadProgress, processName) {
    console.log("fileName:", fileName)
    let formData = new FormData();

    formData.append("file", file);
    formData.append("userId", userId);
    formData.append("filename", fileName);

    return axios.post(server+"/img/"+processName, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });


/*
http.post('/upload', {
    firstName: 'Fred',
    lastName: file
  }, onUploadProgress)
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  */
  }

  getFiles() {
   // return http.get("/files");
  }
}

export default new FileUploadService();