import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'



const DatenschutzDE = () => {



    return (<div style={{ width:"700px"}}>
        <p>Wir freuen uns sehr über Ihr Interesse an Int4Me. Mit dieser Datenschutzerklärung informieren wir Sie über Art, Umfang und Zweck der von uns verarbeiteten personenbezogenen Daten und Ihre Rechte als betroffene Person.</p>
        <p><strong>I. Allgemeines</strong></p>
        <p><strong>1. Verantwortlicher</strong></p>
        <p>Verantwortlich für die Datenverarbeitung gemäß Art.4 Nr. 7 DSGVO ist vorbehaltlich abweichender Angaben in dieser Datenschutzerklärung Thomas Oltzen Carl-von-Ossietzky Str 2 41539 Dormagen (nachfolgend auch „wir“ oder „uns“).</p>
        <p><strong>2. Kontaktdaten</strong></p>
        <p>Bitte wenden Sie sich bei Fragen und Anregungen zum Datenschutz sowie zur Durchsetzung Ihrer Rechte als betroffene Person jederzeit an unseren Datenschutzbeauftragten:</p>
        <p>Thomas Oltzen Carl-von-Ossietzky Str 2 41539 Dormagen</p>
        <p>Soweit es die Datenverarbeitung mit einem gemeinsam Verantwortlichen nach Art. 26 DSGVO erfolgt, agieren beide Verantwortliche als gemeinsame Anlaufstelle.</p>
        <p><strong>3. Aufruf der Website</strong></p>
        <p>Wenn Sie unser Angebot nutzen, werden durch uns automatisch unbedingt erforderliche Cookies gesetzt und folgende Informationen erfasst und verarbeitet, um die Nutzbarkeit der bereitgestellten Funktionen zu ermöglichen und die Sicherheit und Stabilität unseres Angebots zu gewährleisten:</p>
        <p>- Informationen über das zugreifende Endgerät und die verwendete Betriebssystem-Version</p>
        <p>- Datum und Uhrzeit des Zugriffs</p>
        <p>- IP-Adresse</p>
        <p>Die Verarbeitung Ihrer IP-Adresse ist unbedingt erforderlich, um eine Auslieferung unseres Angebots an Ihr Endgerät zu ermöglichen sowie die Leistungsfähigkeit und technische Performance unseres Angebots zu messen. Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO.</p>
        <p>Unsere Server speichern Ihre IP-Adresse außerdem für bis zu 14 Tage zu dem Zweck und dem Interesse, die Sicherheit unseres Angebots gewährleisten zu können (Art. 6 Abs. 1 lit. f DSGVO). Wir verarbeiten ferner Ihre gekürzte IP-Adresse zu dem Zweck und in dem Interesse, die Einspielung von schädlichen Programmen über die angezeigte Werbung verhindern zu können. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f DSGVO. Ihre IP-Adresse bzw. pseudonyme ID wird unmittelbar nach Schließung der jeweiligen Sitzung automatisiert anonymisiert. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.</p>
        <p><strong>4. Ort der Verarbeitung</strong></p>
        <p>Wir verarbeiten personenbezogene Daten auch in Staaten außerhalb des Europäischen Wirtschaftsraumes („EWR“), u. a. den USA. Soweit für das jeweilige Drittland kein Angemessenheitsbeschluss der Europäischen Kommission existiert, haben wir zur Sicherstellung eines angemessenen Datenschutzniveaus mit den eingesetzten Diensten in Drittländern die Standardvertragsklauseln der EU-Kommission gemäß Art. 46 Abs. 2 lit. c DSGVO abgeschlossen:</p>
        <p><a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32010D0087&amp;from=DE" target="_blank" className="o-link" name="inlineLink_">Standardvertragsklauseln mit Auftragsverarbeitern</a></p>
        <p><a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32004D0915&amp;from=DE" target="_blank" className="o-link" name="inlineLink_">Standardvertragsklauseln mit Verantwortlichen</a></p>
        <p>Seit dem 27. September 2021 bedienen wir uns der neuen Standardvertragsklauseln der Europäischen Kommission:</p>
        <p><a href="https://ec.europa.eu/info/sites/default/files/1_de_annexe_acte_autonome_cp_part1_v3.pdf" target="_blank" className="o-link" name="inlineLink_">Standardvertragsklauseln</a></p>
        <p><strong>5. Speicherdauer</strong></p>
        <p>Wir verarbeiten personenbezogene Daten nur, solange dies für den jeweils in dieser Datenschutzerklärung genannten Zweck erforderlich ist. Die personenbezogenen Daten werden anschließend gelöscht, sofern einer Datenlöschung keine gesetzlichen Aufbewahrungsfristen entgegenstehen.</p>
        <p><strong>II. Ihre Rechte als betroffene Person</strong></p>
        <p><strong>1. Auskunft, Berichtigung, Löschung, Einschränkung und das Recht auf Vergessenwerden</strong></p>
        <p>Sie können von uns jederzeit unentgeltlich Auskunft darüber erhalten, ob personenbezogene Daten zu Ihrer Person von uns verarbeitet werden und auch konkret welche Daten über sie gespeichert werden sowie eine Kopie der gespeicherten Daten verlangen. Sie können ferner unrichtige Daten berichtigen und vervollständigen lassen.</p>
        <p>Auch können Sie die Löschung und Einschränkung Ihrer personenbezogenen Daten verlangen. Bitte beachten Sie, dass es z. B. für entgeltliche Verträge wie dem Kauf eines Abonnements von Int4Me gesetzliche Aufbewahrungspflichten gibt und wir daher Ihre Daten nicht in jedem Fall vollständig löschen dürfen. In diesem Fall werden Ihre Daten mit dem Ziel markiert, ihre künftige Verarbeitung einzuschränken.</p>
        <p>Um Auskunft zu erhalten, bzw. zur Löschung Ihrer Daten bitten wir Sie, dieses an <a href="mailto:toltzen@gmail.com" target="_blank" className="o-link" name="inlineLink_">toltzen@gmail.com</a> zu erfragen. Auf diesem Wege können wir unbürokratisch sicherstellen, dass personenbezogene Daten nur an Sie als berechtigte Personen herausgegeben werden, bzw. von Ihnen gelöscht werden. Sind Sie mit der Nutzung des Formulars nicht einverstanden, so schreiben Sie uns dies bitte kurz per E-Mail an <a href="mailto:toltzen@gmail.com" target="_blank" className="o-link" name="inlineLink_">toltzen@gmail.com</a>. Wir werden dann gemeinsam einen anderen Weg finden, der Ihre Identifizierung sicherstellt.</p>
        <p><strong>2.</strong> <strong>Datenübertragbarkeit</strong></p>
        <p>Sofern anwendbar haben Sie außerdem das Recht, dass die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format an Sie oder einen anderen Verantwortlichen übermittelt werden, sofern die Verarbeitung auf der Einwilligung oder einem Vertrag beruht und mithilfe automatisierter Verfahren erfolgt. Dies gilt jedoch nicht, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde. Ferner haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</p>
        <p><strong>3.</strong> <strong>Widerruf / Widerspruch</strong></p>
        <p>Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies können Sie jederzeit über „Widerruf Tracking“ am Seitenende jeder Seite auf Int4Me widerrufen. Im Übrigen können Sie Ihre Einwilligung jederzeit mit Wirkung für die Zukunft unter der o. g. Kontaktadresse widerrufen.</p>
        <p>Ferner haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund eines berechtigten oder öffentlichen Interesses erfolgt, Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
        <p><strong>4.</strong> <strong>Beschwerderecht</strong></p>
        <p>Ferner haben Sie ein Beschwerderecht bei der zuständigen Aufsichtsbehörde sowie die Möglichkeit Rechtsbehelfe einzulegen. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet Sie als Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs.</p>
        <p><strong>5.</strong> <strong>Bestehen einer automatisierten Entscheidungsfindung</strong></p>
        <p>Wir verzichten auf eine automatische Entscheidungsfindung oder ein Profiling im Sinne des Art. 22 DSGVO.</p>
        <p><strong>III. Int4Me Konto</strong></p>
        <p><strong>1. Registrierung</strong></p>
        <p>Für den Abschluss eines Int4Me Kontos ist eine Registrierung im Kaufprozess der Einrichtung erforderlich, sofern Sie noch nicht registriert sind.</p>
        <p><strong>2. Abschluss Int4Me Konto</strong></p>
        <p>Beim Abschluss des Int4Me Kontos verarbeiten wir die von Ihnen im Rahmen des Vertragsschlusses übermittelten personenbezogenen Daten zum Zweck der Vertragsdurchführung und -erfüllung. Rechtsgrundlage für die Datenverarbeitung ist in diesem Fall Art. 6 Abs. 1 lit. b DSGVO. Ferner verarbeiten wir die von Ihnen im Rahmen des Vertragsschlusses übermittelten personenbezogenen Daten zum Zwecke der Erfüllung rechtlicher Pflichten, wie beispielsweise steuerliche Pflichten. Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. c DSGVO i.V.m. § 147 AO; Aufbewahrungspflicht 10 Jahre. Dazu gehört aus umsatzsteuerrechtlichen Gründen auch die Speicherung der IP-Adresse des Geräts, von dem aus Sie das Int4Me Konto abgeschlossen haben.</p>
        <p><strong>3. Unbedingt erforderliche Zugriffe auf Ihr Endgerät</strong></p>
        <p>Wenn Sie Int4Me benutzen, setzen wir nur unbedingt erforderliche Cookies und vergleichbare Technologien ein, d.h. ein Abruf bzw. Speichern von Informationen in ihrem Endgerät erfolgt nur dann, wenn dies für den von Ihnen in Anspruch genommenen Dienst unbedingt erforderlich ist und wir den Dienst anderenfalls nicht erbringen können (§ 25 Abs. 2 TTDSG). Weitere Informationen hierzu können Sie der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a> unter der Sonderkategorie unbedingt erforderlich entnehmen.</p>
        <p><strong>IV. Nutzung von Int4Me mit Tracking und Cookies</strong></p>
        <p><strong>1. Allgemeines</strong></p>
        <p>Wenn Sie in die Nutzung von Int4Me mit Tracking und Cookies einwilligen, werden nicht nur unsere eigenen Inhalte bzw. solche, die in unserem Auftrag (Art. 28 DSGVO) ausgespielt werden, angezeigt, sondern auch Inhalte von Dritten. Hierbei kann es sich um redaktionelle Inhalte, Empfehlungen für externe Inhalte, Spiele und Umfragen, aber auch um Produktwerbung handeln, z. B. in der Form von personalisierten, d.h. auf Ihre mutmaßlichen Interessen auf Basis Ihres Nutzungsverhaltens ausgewählten Werbebannern am Rand der Seite oder Anzeigen unter Artikeln. Rechtsgrundlage der Verarbeitung personenbezogener Daten ist jeweils Art. 6 Abs. 1 lit. a DSGVO.</p>
        <p>Werden solche Inhalte geladen, so erfolgt dies von Servern der jeweiligen Drittanbieter. Dabei findet stets eine Übermittlung bestimmter Daten an die Drittanbieter statt, in vielen Fällen auch personenbezogener Daten. Zudem werden für die meisten Dienste der Drittanbieter, mittels derer die Inhalte geladen werden, Cookies gesetzt oder ähnliche Technologien eingesetzt. Die Zwecke, für die diese Daten von den Drittanbietern in deren eigener, teilweise aber auch in unserer Verantwortung, verarbeitet werden, sind vielfältig.</p>
        <p>Nachfolgend informieren wir Sie über den Einsatz von Cookies bzw. vergleichbaren Technologien und die Verarbeitung personenbezogener Daten durch die Drittanbieter.</p>
        <p>Ohne die Erteilung Ihrer Einwilligung in das Speichern und/oder Abrufen von Informationen auf Ihrem Gerät sowie die Verarbeitung personenbezogener Daten zu den nachfolgend aufgeführten Zwecken ist unsere Website nur bei Abschluss eines Int4Me Kontos nutzbar.</p>
        <p>Die von Ihnen erteilte Einwilligung können Sie jederzeit über den Link "Widerruf Tracking" im Footer der Website durch Anklicken des Widerruf-Buttons widerrufen. Es erscheint dann erneut unser Cookie-Layer, auf dem Sie erneut gefragt werden, wie Sie Int4Me nutzen möchten.</p>
        <p>Bereits gesetzte Cookies können von Ihnen darüber hinaus jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Bitte beachten Sie, dass in diesem Fall auch die von Ihnen erteilte Einwilligung in die Nutzung von Int4Me mit Trackern und Cookies gelöscht wird und Sie bei einem erneuten Besuch unserer Website erneut gefragt werden, wie Sie Int4Me nutzen möchten. Die Laufzeit der eingesetzten Cookies ist vorbehaltlich abweichender Angaben auf die Dauer von maximal einem Jahr beschränkt.</p>
        <p><strong><br /></strong></p>
        <p><strong>2. Cookies und ähnliche Technologien</strong></p>
        <p>Cookies sind kleine Dateien, die Ihr Browser auf Ihrem Gerät in einem dafür vorgesehenen Verzeichnis ablegt. Durch sie kann u. a. festgestellt werden, ob Sie eine Website schon einmal besucht haben. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Websites und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Websites und Servern, den individuellen Browser von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden. Cookies können ohne zusätzliche Informationen Sie als Person nicht identifizieren.</p>
        <p>Cookies und ähnliche Technologien werden benötigt, um (teilweise) personenbezogene Daten zu erheben oder auf Ihrem Endgerät zu speichern und um in der Folge personenbezogene Daten verarbeiten zu können, u. a. zu dem Zweck,</p>
        <p>- einfache Anzeigen auszuwählen,</p>
        <p>- ein personalisiertes Anzeigen-Profil zu erstellen,</p>
        <p>- personalisierte Anzeigen auszuwählen,</p>
        <p>- ein personalisiertes Inhalts-Profil zu erstellen</p>
        <p>- personalisierte Inhalte auszuwählen,</p>
        <p>- Anzeigen-Leistung zu messen,</p>
        <p>- Inhalte-Leistung zu messen,</p>
        <p>- Marktforschung einzusetzen, um Erkenntnisse über Zielgruppen zu gewinnen, und</p>
        <p>- Produkte zu entwickeln und zu verbessern,</p>
        <p>(sog. „IAB-Verarbeitungszwecke“).</p>
        <p>Weitere Informationen zu den IAB-Verarbeitungszwecken und den einzelnen Drittanbietern finden Sie nachfolgend an entsprechender Stelle in dieser Datenschutzerklärung bzw. in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a>.</p>
        <p><strong>3. Drittanbieter</strong></p>
        <p>Die Drittanbieter, deren Dienste bzw. Inhalte wir einbinden, helfen uns, unser Webangebot zu verbessern und zu finanzieren. Eine Übersicht sämtlicher möglicher Drittanbieter finden Sie an entsprechender Stelle in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a>. Die Bezeichnung „Drittanbieter“ ist dabei nicht im Sinne von Art. 4 Nr. 10 DSGVO (Dritter) zu verstehen. Unter den in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a> aufgeführten Drittanbietern befinden sich vielmehr auch unsere Auftragsverarbeiter im Sinne von Art. 28 DSGVO, die in dieser Datenschutzerklärung nicht namentlich benannt sind, da wir gemäß Art. 4 Nr. 7 DSGVO in diesen Fällen für die Datenverarbeitung verantwortlich bleiben. Sie erkennen unsere Auftragsverarbeiter durch entsprechende Kennzeichnung in der „Privatsphäre“ unter dem Link „Drittanbieter“. Ferner befinden sich unter den in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a> aufgeführten Drittanbietern auch die Dritten und Auftragsverarbeiter (Unterauftragnehmer), die von unseren Vertragspartnern und Vermarktern eingesetzt werden. Aus den vorstehenden Gründen sind in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphär</a><a href="/services/article157550705/Datenschutzerklaerung-Int4Me-DIGITAL.html#cmp-layer" target="_blank" className="o-link" name="inlineLink_">e</a> regelmäßig mehr Drittanbieter namentlich aufgeführt als in dieser Datenschutzerklärung.</p>
        <p><strong>4. Versteigerung von Anzeigenplätzen an Werbetreibende (Real Time Bidding)</strong></p>
        <p>Bei der Vermarktung unseres Angebots bedienen wir uns u.a. des sog. „Real Time Bidding“ (RTB) Verfahrens. Beim RTB werden unsere Anzeigenplätze an Werbetreibende automatisiert und in Echtzeit versteigert. Bei der Versteigerung werden insbesondere auch Ihre Nutzungsprofile und Ihre Interessen an die Teilnehmer der Auktionen übermittelt und berücksichtigt. Wir verwenden dabei das OpenRTB-Protokoll, dass diese Echtzeitgebote ermöglicht und einen anerkannten Industriestandart für die Kommunikation und Interoperabilität von Auktionsteilnehmern darstellt.</p>
        <p>­­­Nähere Informationen zu den Nutzungsprofilen und Interessen finden Sie in den folgenden Abschnitten der Datenschutzerklärung.</p>
        <p><strong>5. Int4Me Nutzerkennungen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies und damit in den in der Privatsphäre gelisteten „Drittanbieter“ Int4Me Nutzerkennungen erteilt haben, verarbeiten wir Informationen über Ihre Aktivitäten auf unserer Website zu den IAB-Verarbeitungszwecken „Auswahl einfacher Anzeigen“, „Ein personalisiertes Anzeigenprofil erstellen“ und „personalisierte Anzeigen auswählen“ sowie „Produkte weiterentwickeln und verbessern“ unter einer pseudonymen Kennung („hans“). Weitere Informationen zu den genannten IAB-Verarbeitungszwecken finden Sie an entsprechender Stelle in dieser Datenschutzerklärung. Wir nutzen die Int4Me Nutzerkennung außerdem zum Zwecke der ID-Stabilisierung. Weitere Informationen dazu finden Sie unter der Überschrift „ID-Stabilisierung“. Rechtsgrundlage für die Int4Me Nutzerkennung ist Art. 6 Abs. 1 lit. a DSGVO bzw. § 25 Absatz 1 TTDSG. Informationen zur Rechtsgrundlage der unter der Int4Me Nutzerkennung erfolgenden Datenverarbeitungen finden Sie an entsprechender Stelle in dieser Datenschutzerklärung. Ihre Einwilligung können Sie jederzeit in der Privatsphäre oder auch unter dem Link „Widerruf Nutzerkennungen“ am Ende der Website widerrufen.</p>
        <p> <strong>6. ID-Stabilisierung</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, verarbeiten wir Ihre Int4Me Nutzerkennung - und wenn Sie uns auf einem unserer Onlineangebote Ihre Einwilligung in die Verarbeitung weiterer pseudonymer Identifier (z.B. netID) erteilt haben, auch diese - zur ID-Stabilisierung. Das bedeutet, dass wir die unterschiedlichen Identifier anhand der Int4Me Nutzerkennung einander zuordnen. Dies ermöglicht uns, Ihr Nutzungsverhalten auch auf anderen Onlineangeboten der Int4Me SE zu berücksichtigen und Ihnen auf Basis dessen – sofern Sie hierfür Ihre Einwilligung erteilt haben - personalisierte Werbung auszuspielen. Dies erfolgt in unserem und Ihrem Interesse, unsere Onlineangebote und die dort ausgespielte Werbung noch besser an Ihren Interessen auszurichten. Rechtsgrundlage für die ID-Stabilisierung ist Art. 6 Abs. 1 lit. f DSGVO. Sie haben nach Art. 21 DSGVO das Recht zum Widerspruch gegen die Verarbeitung. Das Recht zum Widerspruch gegen die Verarbeitung können Sie ausüben, indem Sie die Einwilligung in die Nutzung der Int4Me Nutzerkennung bzw. in die Nutzung von Int4Me mit Tracking und Cookies widerrufen. Die Int4Me Nutzerkennung wird dann inaktiv und nicht mehr zur ID-Stabilisierung genutzt. Eine Löschung der Int4Me Nutzerkennung in Ihrem Browser ist jedoch aus technischen Gründen nur Ihnen möglich. Eine weitere Nutzung unseres Onlinedienstes ist dann jedoch nur noch bei Abschluss eines Kontos möglich.</p>
        <p><strong>7. Einfache Anzeigen auswählen</strong></p>
        <p>Wenn sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, verarbeiten wir bzw. die betreffenden Drittanbieter Echtzeit-Informationen über den Kontext, in dem die Anzeige dargestellt wird, einschließlich Informationen über das inhaltliche Umfeld sowie das verwendete Gerät, wie z. B. Gerätetyp und -funktionen, Browser-Kennung, URL, IP-Adresse und die ungefähren Standortdaten zu dem Zweck, die Auswahl und Ausspielung der zur Refinanzierung unseres Angebots ausgespielten Anzeigen zu optimieren, die Häufigkeit und Reihenfolge der Anzeige-Einblendungen zu steuern und um zu verhindern, dass eine Anzeige in einem ungeeigneten redaktionellen Umfeld (brand-unsafe) eingeblendet wird. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO.</p>
        <p>Für die vorgenannten Zwecke bedienen wir uns auch der Dienste nachfolgend aufgeführter Dritter, die gemäß Art. 4 Nr. 7 DSGVO für die über Ihren jeweiligen Dienst stattfindende Datenverarbeitung verantwortlich sind. Weitere Informationen zur Datenverarbeitung durch diese Anbieter und Ihren Rechten als betroffene Person können Sie den nachfolgend verlinkten Datenschutzerklärungen der Anbieter entnehmen:</p>
        <p>· <strong>Google Advertising Products</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="http://www.google.de/intl/de/policies/privacy/" target="_blank" className="o-link" name="inlineLink_">www.google.de/intl/de/policies/privacy/</a></p>
        <p>· <strong>Youtube</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1" target="_blank" className="o-link" name="inlineLink_">https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1</a></p>

        <p>Um die Anzeigen auf unserer Website in der richtigen Größe und in einem von Ihrem Gerät unterstützten Format ausspielen und Sie bei Anklicken der Anzeige auf die entsprechende Zielseite weiterleiten zu können, werden durch den jeweiligen Drittanbieter zudem Ihre IP-Adresse sowie Informationen zu Ihrem Gerätetyp verarbeitet. Hierbei handelt es sich um die Sonderkategorie „Anzeigen oder Inhalte technisch bereitstellen“.</p>
        <p><strong>8. Ein personalisiertes Anzeigen-Profil erstellen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, setzen wir Dienste ein zu dem Zweck, die zur Refinanzierung unseres Angebots ausgespielten Anzeigen an Ihren Interessen auf Basis Ihres Nutzungsverhaltens auszurichten. Zu diesem Zweck wird ein Profil Ihrer Nutzung mit Informationen zu Ihren Aktivitäten, Interessen, den Besuchen auf Webseiten oder der Verwendung von Anwendungen, demographischen Informationen oder des Standorts erstellt. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Für die vorgenannten Zwecke bedienen wir uns auch der Dienste nachfolgend aufgeführter Dritter, die gemäß Art. 4 Nr. 7 DSGVO für die über den Dienst stattfindende Datenverarbeitung verantwortlich sind. Weitere Informationen zur Datenverarbeitung durch die eingesetzten Anbieter und Ihren Rechten als betroffene Person können Sie den nachfolgend verlinkten Datenschutzerklärungen der Anbieter entnehmen:</p>
        <p>· <strong>Google Advertising Products</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="http://www.google.de/intl/de/policies/privacy/" target="_blank" className="o-link" name="inlineLink_">www.google.de/intl/de/policies/privacy/</a></p>
        <p>· <strong>Youtube</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1" target="_blank" className="o-link" name="inlineLink_">https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1</a></p>

        <p><strong>9. Personalisierte Anzeigen auswählen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, setzen wir Dienste ein zu dem Zweck, Ihnen für Sie interessante Anzeigen auf Basis eines von Ihnen erstellten personalisierten Anzeigenprofils ausspielen zu können. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Hierbei bedienen wir uns auch der Dienste nachfolgend aufgeführter Dritter, die gemäß Art. 4 Nr. 7 DSGVO für die über den Dienst stattfindende Datenverarbeitung verantwortlich sind. Weitere Informationen zur Datenverarbeitung durch die eingesetzten Anbieter und Ihren Rechten als betroffene Person können Sie den nachfolgend verlinkten Datenschutzerklärungen dieser Anbieter entnehmen:</p>
        <p>· <strong>Google Advertising Products</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="http://www.google.de/intl/de/policies/privacy/" target="_blank" className="o-link" name="inlineLink_">www.google.de/intl/de/policies/privacy/</a></p>
        <p>· <strong>Youtube</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1" target="_blank" className="o-link" name="inlineLink_">https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1</a></p>

        <p>Um die personalisierten Anzeigen auf unserer Website in der richtigen Größe und in einem von Ihrem Gerät unterstützten Format ausspielen und Sie bei Anklicken der Anzeige auf die entsprechende Zielseite weiterleiten zu können, werden durch den jeweiligen Drittanbieter zudem Ihre IP-Adresse sowie Informationen zu Ihrem Gerätetyp verarbeitet. Hierbei handelt es sich um die Sonderkategorie „Anzeigen oder Inhalte technisch bereitstellen“.</p>

        <p><strong>10. Ein personalisiertes Inhalts-Profil erstellen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, erstellen wir zu dem Zweck, die Auswahl von Inhalten (nicht Werbung) in unserem Angebot auf Ihr Nutzungsverhalten und demzufolge an Ihren mutmaßlichen Interessen auszurichten, ein Profil Ihrer Nutzung mit Informationen zu den Aktivitäten, Interessen und Besuchen auf Webseiten oder der Verwendung von Anwendungen, demographischen Informationen oder des Standorts. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO.</p>
        <p>Darüber hinaus bedienen wir uns der Dienste nachfolgend aufgeführter Dritter, die gemäß Art. 4 Nr. 7 DSGVO für die über den Dienst stattfindende Datenverarbeitung verantwortlich sind. Weitere Informationen zur Datenverarbeitung durch diese Anbieter und zu Ihren Rechten als betroffene Person können Sie den nachfolgend verlinkten Datenschutzerklärungen dieser Anbieter entnehmen:</p>
        <p>· <strong>Google Advertising Products</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="http://www.google.de/intl/de/policies/privacy/" target="_blank" className="o-link" name="inlineLink_">www.google.de/intl/de/policies/privacy/</a></p>
        <p>· <strong>Youtube</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1" target="_blank" className="o-link" name="inlineLink_">https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1</a></p>

        <p><strong>11. Anzeigen-Leistung messen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben,, setzen wir Dienste ein zu dem Zweck, die Anzeigen-Leistung zu messen. Diese Dienste messen u.a., ob und wie Anzeigen bei einem Nutzer eingeblendet wurden und wie dieser mit ihnen interagiert hat, ob eine Anzeige in einem ungeeigneten redaktionellen Umfeld (brand-unsafe) eingeblendet wird und bestimmen, zu welchem Prozentsatz die Anzeige hätte wahrgenommen werden können, einschließlich der Dauer (Werbewahrnehmungschance). Sie erstellen zudem Berichte über Anzeigen, einschließlich ihrer Wirksamkeit und Leistung, über die Interaktion von Nutzern mit Anzeigen, und zwar anhand von Daten, die im Laufe der Interaktion des Nutzers mit dieser Anzeige gemessen wurden. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Wir bedienen uns auch der Dienste nachfolgend aufgeführter Dritter, die gemäß Art. 4 Nr. 7 DSGVO für die über den Dienst stattfindende Datenverarbeitung verantwortlich sind. Weitere Informationen zur Datenverarbeitung durch diese Anbieter und Ihren Rechten als betroffene Person können Sie den nachfolgend verlinkten Datenschutzerklärungen dieser Anbieter entnehmen:</p>
        <p>· <strong>Google Advertising Products</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="http://www.google.de/intl/de/policies/privacy/" target="_blank" className="o-link" name="inlineLink_">www.google.de/intl/de/policies/privacy/</a></p>
        <p>· <strong>Youtube</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1" target="_blank" className="o-link" name="inlineLink_">https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1</a></p>

        <p>Als Teil der Google Advertising Products nutzen wir auf unserer Website zur Verbesserung der Messung unserer Online-Anzeigen das Feature Google Enhanced Conversions. Die hierbei verarbeiteten Daten helfen uns, Conversions effektiver nachzuvollziehen, die andernfalls nicht erfasst worden wären und unsere Angebote zu optimieren. Wenn Sie von einer Google-Anzeige auf unsere Website weitergeleitet werden und beginnen ein Abo abzuschließen, senden wir einen pseudonymen Identifier (z.B. gehashte Email-Adresse, mit der Sie bereits bei Google angemeldet sind) und Informationen über diesen möglichen Abo-Abschluss an Google. Hierzu wird der sichere Einweg-Hash-Algorithmus SHA256 auf die E-Mail-Adresse angewendet, bevor sie an Google gesendet wird. Die Hash-Daten werden dann mit angemeldeten Google-Konten abgeglichen. Google wird diese Informationen ausschließlich verwenden, um zu verstehen, auf welche Anzeige Sie geklickt haben, um den Erfolg bestimmter Anzeigen zu messen und um uns diese Informationen in aggregierter Form zur Verfügung zu stellen. Insbesondere wird Google die Daten nicht verwenden, um gezielt Werbung an Sie oder an andere Nutzer auszuspielen, oder die Daten zu sonstigen Zwecken speichern oder verwenden. Wir übermitteln diese Daten auf Basis unseres berechtigten Interesses an einer Steuerung und Optimierung unserer Marketingaktivitäten gemäß Art. 6 Abs. 1 lit. f DSGVO. Sie können dieser Verarbeitung jederzeit widersprechen, indem Sie am Ende der Website unter dem Link „Widerruf Tracking“ Ihre Einwilligung widerrufen.</p>
        <p><strong>12.</strong> <strong>Inhalte-Leistung messen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, setzen wir Dienste ein zu dem Zweck, die Leistung von Inhalten unseres Angebots zu messen. Hierbei wird gemessen und in einem Report dargestellt, wie Inhalte an Nutzer ausgeliefert werden und wie diese mit ihnen interagiert haben. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Wir bedienen uns auch der Dienste nachfolgend aufgeführter Dritter, die gemäß Art. 4 Nr. 7 DSGVO für die über den Dienst stattfindende Datenverarbeitung verantwortlich sind. Weitere Informationen zur Datenverarbeitung durch diese Anbieter und zu Ihren Rechten als betroffene Person können Sie den nachfolgend verlinkten Datenschutzerklärungen der Anbieter entnehmen:</p>
        <p>· <strong>Google Advertising Products</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="http://www.google.de/intl/de/policies/privacy/" target="_blank" className="o-link" name="inlineLink_">www.google.de/intl/de/policies/privacy/</a></p>
        <p>· <strong>Youtube</strong> (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland), <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1" target="_blank" className="o-link" name="inlineLink_">https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&amp;hl=de&amp;visit_id=0-636644030056539000-341535836&amp;rd=1</a></p>

        <p>* Am Beispiel von Facebook Conversion Tracking: Wir verwenden innerhalb unseres Internetauftritts den „Custom Audiences“ Pixel des sozialen Netzwerks Facebook. Dabei sind sog. Zählpixel auf unseren Seiten integriert. Wenn Sie unsere Seiten besuchen, wird über das Zählpixel eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch u. a. von Ihrem Browser die Information, dass von Ihrem Endgerät unsere Seite aufgerufen wurde. Wenn Sie Facebook-Nutzer sind, kann Facebook hierdurch den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Facebook kann dann die Leistung von Inhalten und Anzeigen messen sowie Werbeanzeigen auf Facebook gezielt ausspielen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Wir können lediglich auswählen, welchen Segmenten von Facebook-Nutzern (wie Alter, Interessen) unsere Werbung angezeigt werden soll. Hierbei handelt es sich nicht um den Dienst „Facebook Custom Audiences über die Kundenliste“. Wir übermitteln an Facebook keine Kundendaten, insbesondere keine E-Mail-Adressen – weder verschlüsselt noch unverschlüsselt. Die Funktion „erweiterter Abgleich“ ist deaktiviert.</p>
        <p>Für die über Facebook Business Tools der Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland („Facebook“) erhobenen Informationen sind wir mit Facebook gemeinsam verantwortlich. Informationen zu Facebook und die Kontaktdaten des Datenschutzbeauftragten sowie weitere Informationen dazu, wie Facebook personenbezogene Daten verarbeitet, einschließlich der Rechtsgrundlage und der Möglichkeiten zur Wahrnehmung Ihrer Rechte als betroffene Person gegenüber Facebook können Sie der <a href="https://www.facebook.com/about/privacy " target="_blank" className="o-link" name="inlineLink_">Datenrichtlinie</a> von Facebook entnehmen. Wir haben mit Facebook eine Vereinbarung über die gemeinsame Verantwortung (Art. 26 DSGVO) geschlossen, in der wir die jeweiligen Verantwortlichkeiten für die Erfüllung der Verpflichtungen gemäß der DSGVO hinsichtlich der gemeinsamen Verarbeitung festgelegt haben. Danach sind wir verpflichtet, die vorstehenden Informationen bereitzustellen, und Facebook hat die Verantwortung für die weiteren Betroffenenrechte gemäß Art. 15-20 der DSGVO übernommen.</p>
        <p><strong>13. Marktforschung einsetzen, um Erkenntnisse über Zielgruppen zu gewinnen</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, setzen wir Dienste ein zu dem Zweck, Erkenntnisse über Zielgruppen von Werbung und Inhalten zu erzielen. Hierbei werden aggregierte Berichte für Werbetreibende oder deren Repräsentanten über die Zielgruppen, die durch ihre Anzeigen erreicht werden, und welche auf der Grundlage von Befragungspanels oder ähnlichen Verfahren gewonnen wurden, erstellt. Ferner werden aggregierte Berichte für Diensteanbieter über die Zielgruppen erstellt, die durch die Inhalte und/oder Anzeigen auf ihren Diensten erreicht wurden bzw. mit diesen interagiert haben und welche über Befragungspanels oder ähnliche Verfahren ermittelt wurden. Die Berichte ermöglichen dabei keinen Rückschluss auf eine bestimmte oder bestimmbare Person. Zur Erstellung dieser Berichte werden jedoch Informationen über Ihr Nutzungsverhalten verarbeitet. Darüber hinaus werden für Zwecke der Marktforschung Offline-Daten zu einem Online-Benutzer zugeordnet, um Erkenntnisse über Zielgruppen zu gewinnen, soweit Anbieter erklärt haben, Offline-Datenquellen abzugleichen und zusammenzuführen. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO.</p>
        <p><strong>14. Produkte entwickeln und verbessern</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, setzen wir Dienste ein zu dem Zweck, Produkte zu entwickeln und zu verbessern, z.B. durch neue Funktionen. Hierfür werden Informationen über Ihr Nutzungsverhalten verarbeitet. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO.</p>

        <p><strong>15. Gewährleistung von Sicherheit, Verhinderung von Betrug und Behebung von Fehlern (Sonderkategorie)</strong></p>
        <p>Wenn Sie Ihre Einwilligung in die Nutzung von Int4Me mit Tracking und Cookies erteilt haben, verarbeiten die in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a> unter der entsprechenden Sonderkategorie aufgeführten Drittanbieter zum Zwecke der Gewährleistung von Sicherheit, Verhinderung von Betrug und Behebung von Fehlern Informationen, mit denen sie sicherstellen, dass Daten sicher übermittelt werden, schädliche, betrügerische, ungültige oder illegale Aktivitäten ausfindig gemacht und verhindert werden und um den korrekten und effizienten Betrieb von Systemen und Prozessen - einschließlich der Überwachung und Verbesserung der Leistung von Systemen und Prozessen - im Rahmen der erlaubten Verarbeitungszwecke sicherzustellen. Dies ist notwendig, um die Sicherheit der jeweiligen Datenverarbeitung zu gewährleisten, Betrug zu verhindern und Fehler beheben zu können. Die zu diesem Zweck verarbeiteten Informationen können automatisch gesendete Geräteeigenschaften zur Identifizierung beinhalten, präzise Geolokalisierungsdaten und Daten, die durch Auslesung der Geräteeigenschaften zur Identifizierung ohne gesonderte Offenlegung und/oder Opt-in. Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 lit. a DSGVO.</p>


        <p><strong>VI. Diese Website verwendet Google AdSense.</strong></p>
        <p> Es handelt sich hierbei um einen Dienst
            der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, zur
            Einbindung von Werbeanzeigen. Google AdSense verwendet Cookies. Dies sind
            Dateien, durch deren Speicherung dem PC Google die Daten Ihrer Benutzung
            unserer Website analysieren kann. Zudem werden bei Google AdSense zusätzlich
            Web Beacons verwendet, nicht sichtbare Grafiken, die es Google ermöglichen, Klicks
            auf dieser Website, den Verkehr auf dieser und ähnliche Informationen zu
            analysieren.</p>
        <p>Die über Cookies und Web Beacons erhaltenen Informationen, Ihre IP-Adresse
            sowie die Auslieferung von Werbeformaten werden an einen Server von Google mit
            Standort in den USA übermittelt und dort gespeichert. Google wird diese
            gesammelten Informationen möglicherweise an Dritte weitergeben, wenn dies
            gesetzlich erforderlich ist oder Google gegenüber Dritten die Datenverarbeitung in
            Auftrag gibt. Allerdings wird Google Ihre IP-Adresse zusammen mit den anderen
            gespeicherten Daten zusammenführen.</p>
        <p>Durch entsprechende Einstellungen an Ihrem Internetbrowser können Sie
            verhindern, dass die genannten Cookies auf Ihrem PC gespeichert werden. Dadurch
            besteht jedoch die Möglichkeit, dass die Inhalte dieser Website nicht mehr in
            gleichem Umfang genutzt werden können. Durch die Nutzung dieser Website willigen
            Sie in die Bearbeitung der zu Ihrer Person erhobenen Daten durch Google in der
            zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck ein.
        </p>
        <p><strong>VI. Besondere Dienste</strong></p>
        <p><strong>1. Registrierung</strong></p>
        <p>Für das Bereitstellen bestimmter Inhalte oder Leistungen ist die vorherige Anmeldung mittels eines bestehenden Benutzerkontos erforderlich. Für das Benutzerkonto ist eine Registrierung notwendig. Wir setzen dabei unseren portalübergreifenden Dienst myPass ein, der Ihnen die Anmeldung auf allen Angeboten der Int4Me SE ermöglicht, die myPass ebenfalls integriert haben. Für die Nutzung von myPass gelten <a href="https://www.mypass.de/information/nutzungsbedingungen-fuer-mypass " target="_blank" className="o-link" name="inlineLink_">die Nutzungsbedingungen von mypass</a>. Mehr Informationen zu myPass finden Sie hier: www.mypass.de Weitere Informationen zur Datenverarbeitung können Sie dem <a href="https://www.mypass.de/datenschutz " target="_blank" className="o-link" name="inlineLink_">Datenschutzhinweis</a> für den Single-Sign-On-Dienst myPass entnehmen. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO. Unter folgendem Link können Sie Ihre Registrierungsdaten einsehen und verwalten: <a href="/meinewelt/" target="_blank" className="o-link" name="inlineLink_">https://www.welt.de/meinewelt/</a></p>
        <p><strong>2. Abonnement und zahlungspflichtige Dienste</strong></p>
        <p>Beim Abschluss eines Abonnements oder bei Bezug eines zahlungspflichtigen Dienstes verarbeiten wir die von Ihnen im Rahmen des Vertragsschlusses übermittelten personenbezogenen Daten zum Zwecke der Vertragsdurchführung und -erfüllung. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO. Entsprechendes gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen. Ferner sind wir gehalten, personenbezogene Daten zum Zwecke der Erfüllung rechtlicher Pflichten zu verarbeiten, wie beispielsweise steuerliche Pflichten. Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. c DSGVO i.V.m. § 147 AO; Aufbewahrungspflicht 10 Jahre. Dazu gehört aus umsatzsteuerrechtlichen Gründen auch die Speicherung der IP-Adresse des Geräts, von dem aus Sie bei Abschluss des Vertrages auf die betreffende Website zugegriffen haben.</p>
        <p>Wir übermitteln Ihre im Rahmen eines Abonnements oder zahlungspflichtigen Dienstes mitgeteilten personenbezogenen Daten nur dann an Dritte, wenn die Übermittlung erforderlich ist, um unsere vertraglichen Verpflichtungen Ihnen gegenüber zu erfüllen und dies ersichtlich bei oder gemeinsam mit einem anderen Anbieter erfolgt (z. B. bei Kooperationen), wir zur Weitergabe in sonstiger Weise berechtigt oder verpflichtet sind oder Sie uns eine entsprechende Einwilligung erteilt haben. Sofern Sie ein Abo mit uns haben, erfolgt die Logistik, insbesondere der Versand bzw. die Zustellung durch verschiedene Zustellpartner.</p>
        <p><strong>3. Zahlungsdienste</strong></p>
        <p>Für den Fall, dass Sie einen zahlungspflichtigen Dienst nutzen oder etwas über unsere Website erwerben, bieten wir verschiedene Zahlungsmethoden folgender Anbieter an, die jeweils verantwortlich für die Datenverarbeitung gemäß Art. 4 Nr. 7 DSGVO sind:</p>


        <p><strong>Amazon Payment</strong><br />Amazon Pay ist eine Zahlungsmöglichkeit von Amazon Europe Core S.à r.l. (Société à responsabilité limitée), 5 Rue Plaetis, L-2338 Luxemburg. Mit Amazon Pay können Sie somit auf Websites von Drittanbietern sicher einkaufen, ohne Ihre Zahlungsdaten offenlegen zu müssen. Des Weiteren entstehen Ihnen keine zusätzlichen Kosten, wenn Sie mit Amazon Pay bezahlen. Wenn Sie bereits ein Amazon.de-Kundenkonto haben, wurde automatisch ein Amazon Pay-Konto für Sie erstellt. Dieses wird aktiviert, wenn Sie erstmals auf der Website eines Drittanbieters, der Amazon Pay akzeptiert, bestellen. Dieses Konto ist mit Ihrem Amazon.de-Kundenkonto verknüpft und Sie können sofort mit der dort hinterlegten Zahlungsart - entweder per Bankeinzug oder mit Kreditkarte - bezahlen. Amazon Pay gibt die in Ihrem Amazon.de-Kundenkonto hinterlegten Zahlungsdaten nicht an den Verkäufer weiter und auch Sie müssen diese bei Bestellaufgabe nicht angeben. <a href="https://pay.amazon.com/de/help/201754560" target="_blank" className="o-link" name="inlineLink_">Hier</a> erfahren Sie mehr zur Zahlung mit Amazon Pay auf Websites von Drittanbietern. Weitere Informationen finden Sie auf der Amazon Pay-Website unter <a href="https://pay.amazon.com/de" target="_blank" className="o-link" name="inlineLink_">https://pay.amazon.com/de</a>.</p>
        <p><strong>PayPal</strong></p>
        <p>Wir haben auf dieser Website die Zahlungsmöglichkeit PayPal integriert. PayPal ist ein Online-Zahlungsdienstleister der PayPal (Europe) S.à.r.l. &amp; Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg, Luxemburg. Zahlungen werden über sogenannte PayPal-Konten abgewickelt, die virtuelle Privat- oder Geschäftskonten darstellen. Zudem besteht bei PayPal die Möglichkeit, virtuelle Zahlungen über Kreditkarten abzuwickeln, wenn ein Nutzer kein PayPal-Konto unterhält. Ein PayPal-Konto wird über eine E-Mail-Adresse geführt, weshalb es keine klassische Kontonummer gibt. PayPal ermöglicht es, Online-Zahlungen an Dritte auszulösen oder auch Zahlungen zu empfangen. PayPal übernimmt ferner Treuhänderfunktionen und bietet Käuferschutzdienste an. Wählt die betroffene Person während des Bestellvorgangs in unserem Online-Shop als Zahlungsmöglichkeit „PayPal“ aus, werden automatisiert Daten der betroffenen Person an PayPal übermittelt. Mit der Auswahl dieser Zahlungsoption willigt die betroffene Person in die zur Zahlungsabwicklung erforderliche Übermittlung personenbezogener Daten ein. Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich in der Regel um Vorname, Nachname, Adresse, Email-Adresse, IP-Adresse, Telefonnummer, Mobiltelefonnummer oder andere Daten, die zur Zahlungsabwicklung notwendig sind. Zur Abwicklung des Kaufvertrages notwendig sind auch solche personenbezogenen Daten, die im Zusammenhang mit der jeweiligen Bestellung stehen. Die Übermittlung der Daten bezweckt die Zahlungsabwicklung und die Betrugsprävention. Wir werden PayPal personenbezogene Daten insbesondere dann übermitteln, wenn ein berechtigtes Interesse für die Übermittlung gegeben ist.</p>
        <p>Die zwischen PayPal und dem für uns ausgetauschten personenbezogenen Daten werden von PayPal unter Umständen an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. PayPal gibt die personenbezogenen Daten gegebenenfalls an verbundene Unternehmen und Leistungserbringer oder Subunternehmer weiter, soweit dies zur Erfüllung der vertraglichen Verpflichtungen erforderlich ist oder die Daten im Auftrag verarbeitet werden sollen.</p>
        <p>Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit personenbezogenen Daten jederzeit gegenüber PayPal zu widerrufen. Ein Widerruf wirkt sich nicht auf personenbezogene Daten aus, die zwingend zur (vertragsgemäßen) Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden müssen.</p>
        <p>Die geltenden Datenschutzbestimmungen von PayPal können unter https://www.paypal.com/de/webapps/mpp/ua/privacy-full abgerufen werden.</p>
        <p><strong>4. Mein Int4Me-Konto</strong></p>
        <p>Sofern Sie sich für Mein Int4Me-Konto registriert und kein Int4Me Konto abgeschlossen haben, führen wir Ihre Nutzer-ID mit Ihrer im Registrierungsprozess angegebenen E-Mail-Adresse und mit pseudonymisierten Daten Ihres Nutzerverhaltens bei Int4Me (Nutzungsdaten) sowie, soweit Sie über unsere Website ein kostenpflichtiges Produkt bestellt haben – mit Ihren Bestelldaten (d.h. das/die von Ihnen bestellten Produkt/-e, das Datum der Bestellung und das Datum des möglichen Endes Ihre Abonnements) zusammen, um Ihnen die personalisierten Dienste bei Int4Me erbringen zu können.</p>
        <p>Bei Ihrer Nutzer-ID handelt es sich um eine Ihnen bei der Registrierung nach dem Zufallsprinzip generierten Identifikationsnummer, unter der Sie als Nutzer geführt werden und die uns ermöglicht, Sie in der Folge als berechtigen Nutzer identifizieren und Ihnen Zugang zu den Diensten zu geben.</p>
        <p>Die hierfür erforderlichen Nutzungsdaten erheben wir durch Cookies und ähnliche Technologien. Andernfalls können wir Ihnen Ihr Mein Int4Me-Konto und dessen Leistungen nicht wie vorgesehen verfügbar machen. Weitere Informationen hierzu finden Sie an entsprechender Stelle in dieser Datenschutzerklärung bzw. in der <a href="#cmp-layer" target="_blank" className="o-link" name="inlineLink_">Privatsphäre</a>.</p>
        <p>Bei den Nutzungsdaten handelt es ich u.a. um die folgenden Informationen:</p>
        <p>- Häufigkeit und Dauer der Nutzung<br />- Artikel- und Bildaufrufe<br />- Abspielen von Audio- und Videoformaten<br />- Region der Nutzung<br />- Aktionen (z.B. Inhalte teilen, Kommentare)</p>
        <p>Wir verarbeiten die Daten zu dem Zweck, Ihnen bei Int4Me auf Ihre Interessen und Ihre Nutzung abgestimmte Inhalte anzeigen zu können und Ihnen per E-Mail für Sie interessante Artikel und Inhalte zu empfehlen. Sofern Sie die Zusendung personalisierter Artikelempfehlungen per E-Mail nicht wünschen sollten, haben Sie jederzeit die Möglichkeit, diesen Dienst zu deaktivieren, z.B. durch Anklicken des hierfür vorgesehenen Links in jeder Artikelempfehlung bzw. im Bereich „Meine Newsletter“.</p>
        <p>Rechtsgrundlagen der Datenverarbeitung sind die Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO) sowie Ihre Einwilligung in die Erstellung von Nutzungsprofilen und in die Zusammenführung der Daten (Art. 6 Abs. 1 lit. a DSGVO). Ihre Einwilligung in die Erstellung von Nutzungsprofilen können Sie jederzeit über den Link <a href="#cmp-opt-out" target="_blank" className="o-link" name="inlineLink_">„Widerruf Tracking“ </a>durch Anklicken des Widerruf-Buttons widerrufen. Bitte beachten Sie aber, dass wir den personalisierten Dienst im Int4Me Konto nicht bereitstellen können.</p>
        <p><strong>5. Mailings und Newsletter</strong></p>
        <p>Die von Ihnen im Zusammenhang mit dem Verkauf einer Ware oder Dienstleistung erhobene E-Mail-Adresse verwenden wir außerdem, um Sie per E-Mail über eigene ähnliche Waren oder Dienstleistungen sowie über bestehende Abonnements oder generell über unsere Onlinedienste zu informieren, sofern wir Sie bei Erhebung der E-Mail-Adresse hierauf hingewiesen und Sie dem nicht widersprochen haben. Die Verarbeitung der E-Mail-Adresse erfolgt in diesem Fall auf Grundlage unseres berechtigten Interesses an der Bewerbung unserer Waren und Dienstleistungen (Art. 6 Abs. 1 lit. f DSGVO, § 7 Abs. 3 UWG). Sie haben jederzeit die Möglichkeit, der Zusendung dieser Informationen über weitere Produktangebote zu widersprechen, z.B. durch Anklicken des hierfür in jeder dieser E-Mails vorgesehenen Abbestelllinks.</p>
        <p>Sofern Sie einen unserer Mailings bzw. Newsletter bestellt haben, nutzen wir Ihre E-Mail-Adresse, um Ihnen den von Ihnen bestellten Newsletter zuzusenden. Rechtsgrundlage für die Datenverarbeitung ist in diesen Fällen Art. 6 Abs. 1 lit. a DSGVO. Sie haben jederzeit die Möglichkeit, eine von Ihnen erteilte Einwilligung mit Wirkung für die Zukunft zu widerrufen bzw. den bestellten Newsletter wieder abzubestellen, z.B. durch Anklicken des hierfür in jedem Newsletter vorgesehenen Abbestelllinks.</p>
        <p><strong>6. Personalisierte Produkt- und Medienangebote per E-Mail bzw. Push-Nachrichten</strong></p>
        <p>Sofern Sie Ihre Einwilligung in die Zusendung von Newslettern per E-Mail und/oder Push-Nachrichten mit Produktangeboten und/oder Medienangebote auf Basis Ihrer persönlichen Nutzung der Int4Me Medien erteilt und kein Int4Me Konto abgeschlossen haben, führen wir Ihr pseudonymes Nutzungsprofil (Nutzungsdaten) mit Ihrer nach dem Zufallsprinzip generierten Identifikationsnummer (Nutzer-ID) und Ihrer E-Mail-Adresse zusammen. Bei den erfassten Nutzungsdaten handelt es ich u.a. um die folgenden Informationen:</p>
        <p>- Häufigkeit und Dauer der Nutzung</p>
        <p>- Artikel- und Bildaufrufe</p>
        <p>- Abspielen von Audio- und Videoformaten</p>
        <p>- Region der Nutzung</p>
        <p>- Aktionen (z.B. Inhalte teilen, Kommentare)</p>
        <p>Die Datenzusammenführung erfolgt zu dem Zweck, Ihnen per E-Mail entsprechend Ihren Interessen auf Basis Ihres Nutzungsverhaltens interessante Werbung gemäß Ihrer Einwilligung zuzusenden.</p>
        <p>Die hierfür erforderlichen Nutzungsdaten erheben wir mittels Cookies und ähnlichen Technologien.</p>
        <p>Soweit Sie über unsere Website ein kostenpflichtiges Produkt bestellt haben, führen wir mit Ihrer Einwilligung darüber hinaus Daten über Ihre Bestellung(en), wie das von Ihnen bestellte Produkt, das Datum der Bestellung und das Datum des möglichen Endes Ihre Abonnements zu den vorstehend genannten Daten hinzu. Dies erfolgt zu dem Zweck, Ihnen auch unter Berücksichtigung der von Ihnen erworbenen Produkte für Sie interessante Werbung auszuspielen. Rechtsgrundlage für die Zusammenführung der Daten zu diesem Zweck ist Art. 6 Abs. 1 lit a DSGVO. Sie können Ihre diesbezügliche Einwilligung jederzeit mit Wirkung für die Zukunft insgesamt widerrufen, z.B. durch Anklicken des hierfür in jedem personalisierten Newsletter vorgesehenen Abbestelllinks. Ihre Einwilligung in die Erstellung von Nutzungsprofilen können Sie zudem jederzeit über den Link <a href="#cmp-opt-out" target="_blank" className="o-link" name="inlineLink_">„Widerruf Tracking“</a> durch Anklicken des Widerruf-Buttons widerrufen. Bitte beachten Sie aber, dass wir Ihnen dann keine personalisierten Produkt- und Medienangebote mehr per E-Mail bzw. Push-Nachrichtenden schicken können.</p>
        <p><strong>7. E-Mail-Tracking</strong></p>
        <p>Sofern Sie einen unserer Mailings oder Newsletter bzw. personalisierte Produkt- und Medienangebote per E-Mail abonniert haben, sind in den E-Mails sind sogenannte Zählpixel integriert, zu dem Zweck und in unserem Interesse, unseren Newsletter optimieren zu können. Wir erhalten hierdurch die Information, welcher Browser und welches Betriebssystem verwendet wird, welche IP-Adresse gerade zugreift und zugleich, wer der Zugangsprovider ist. Zudem wird erkannt, ob die E-Mail gelesen und ob anschließend der in der E-Mail integrierte Link angeklickt und die jeweilige Website über diese IP-Adresse besucht wurde. Zusätzlich wird die Anzahl der Zugriffe auf die Webseite gezählt sowie die entsprechende Tageszeit festgehalten, wann die E-Mail abgerufen und der Zugriff getätigt wurde. Auch der ungefähre Standort des Nutzers wird übermittelt. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Sollten Sie dies nicht wünschen, können Sie den Newsletter jederzeit wie vorstehend beschrieben abbestellen und damit Ihre Einwilligung widerrufen.</p>
        <p><strong>9. Kommentarfunktion und Umfragen</strong></p>
        <p>Wir bieten auf unserer Website und in Blogs sowie auf allgemeinen Feedback-Seiten vereinzelt die Möglichkeit an, Kommentare und Feedback abzugeben oder Bugs und fehlerhafte Inhalte zu melden sowie an Umfragen teilzunehmen. Wir verarbeiten in diesen Fällen die Daten, die Sie uns übermitteln. Wenn Sie einen Kommentar veröffentlichen möchten, müssen Sie die hierfür vorgesehenen Felder ausfüllen. Sie können hierbei auch ein Pseudonym verwenden. Wenn Sie die Felder nicht ausfüllen, können Sie keinen Kommentar veröffentlichen. Das gleiche gilt bei Umfragen, an denen Sie teilnehmen. Wir erheben und verarbeiten die von Ihnen im Rahmen der Dienste übermittelten personenbezogenen Daten, um Ihnen die Nutzung des Dienstes zu ermöglichen. Insbesondere benötigen wir hierbei auch Ihre E-Mail-Adresse, um Sie im Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b DSGVO. Kommentare werden dabei vor einer Veröffentlichung auf mögliche Verstöße gegen unsere Netiquette-Regeln überprüft. Rechtsgrundlage für die Datenverarbeitung ist in diesem Fall Art. 6 Abs. 1 lit. f DSGVO und erfolgt in unserem Interesse, Verstöße gegen unsere Netiquette-Regeln im Vorfeld festzustellen und eine Veröffentlichung in diesem Fall zu unterbinden. Ihre E-Mail-Adresse verarbeiten wir in dem Interesse und zu dem Zweck, Sie im Falle von Beanstandungen eines von Ihnen veröffentlichten Kommentars kontaktieren und Ihnen die Möglichkeit zur Stellungnahme geben zu können. Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.</p>
        <p>Zur Analyse von Nutzerkommentaren hinsichtlich der Einhaltung unserer Netiquette-Regeln nutzen wir das Moderations-Tool Conversario der ferret go GmbH, Brauerstraße 14, 16321 Bernau bei Berlin (ferret go). Folgende Daten werden in unserem Auftrag (Art. 28 DSGVO) erfasst und gespeichert: NutzerID / NutzerName (Facebook Name) / Nutzerbild / Kommentartext / KommentarID / Datum &amp; Uhrzeit des Kommentars. Conversario Onsite: NutzerName (nicht Personen identifizierbar) Kommentartext / KommentarID / Datum &amp; Uhrzeit des Kommentars.</p>
        <p><strong>8. Ihre Kontaktaufnahme</strong></p>
        <p>Bei Ihrer Kontaktaufnahme mit uns über eines unserer Kontaktformulare oder per E-Mail werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns verarbeitet, um Ihre Anfrage zu bearbeiten und ggf. mit Ihnen Kontakt aufzunehmen. Die in diesem Zusammenhang anfallenden Daten löschen wir, wenn sie für den vorgenannten Zweck nicht mehr erforderlich sind, sofern der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 lit. f DSGVO. Wenn Sie uns personenbezogene Daten einer anderen Person mitteilen, müssen Sie hierfür zuvor die erforderlichen datenschutzrechtlichen Maßnahmen ergreifen, z.B. eine erforderliche vorherige Einwilligung dieser Person einholen und sie darüber informieren, , zu welchem Zweck Sie die Daten an uns übermitteln..</p>
        <p>Vereinzelt stellen wir auch Kontaktformulare von Anbietern bereit, zu dem Zweck und in dem Interesse, Ihnen eine unmittelbare Kontaktaufnahme zu dem jeweils in dem Kontaktformular ausgewiesenen Anbieter zu ermöglichen. Die von Ihnen in das Kontaktformular eingegebenen personenbezogenen Daten werden über eine Schnittstelle direkt an den Anbieter weitergeleitet. Wir haben keinen Zugriff auf die Daten, eine (Zwischen-)Speicherung der Daten findet nicht statt. Informationen zur Datenverarbeitung entnehmen Sie der jeweils im Kontaktformular verlinkten Datenschutzerklärung des Anbieters. Rechtsgrundlage für die Datenübermittlung ist Art. 6 Abs. 1 lit. b bzw. Art. 6 Abs. 1 lit. f DSGVO.</p>
        <p><strong>Stand: </strong>22.10.2022</p>
    </div>
    )
}

export default DatenschutzDE;