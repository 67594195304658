import React, { useState } from 'react'
import { MainMenu } from './MainMenu';
import { BaseArea } from './BaseArea';
// import GoogleAds from './area/GoogleAds';
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import DenoisingArea, {DenoisingModel} from './area/DenoisingArea'
import DatenschutzDE from './area/DatenschutzDE'
import DatenschutzEN from './area/DatenschutzEN'
import WhatIsAModel from './area/wiki/WhatIsAModel'
import TargetDE from './area/TargetDE'
import TargetEN from './area/TargetEN'
import Service from './area/Services'
import Impressum from './area/Impressum'
import Welcome from './area/Welcome'
import NewsArea from './area/NewsArea'


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import 'react-image-crop/dist/ReactCrop.css'
import './App.css';
import {getUserId} from "./environment";


function langPriority(code) {
  for (var i = 0; i < navigator.languages.length; i++) {
    if (navigator.languages[i].startsWith(code)) {
      return i;
    }
  }
  return 100;
}


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      language: langPriority("de") < langPriority("en") ? "de" : "en",
      label: "welcome",
      page: null
    };
    this.label = 'welcome';
    this.language = '';
    this.translation = {};
    this.changeLanguage = (code) => {
      this.language = code;
      this.state.language = code;
      this.translation = require('./assets/locales/' + this.language.toLowerCase() + '/translation.json');

      this.applicationArea = {
        denoising: <DenoisingArea app={this} model={{}}/>
      }

      this.showArea(this.label);
    }





    this.userId = null;



    this.changeLanguage(this.state.language);
    getUserId();

  }

  /*      <ins class="adsbygoogle"
       style="display:inline-block;width:360px;height:800px"
       data-ad-client="ca-pub-5694346183952215"
       data-ad-slot="4829869557"></ins>*/

  showArea(label) {
    if (label === 'datenschutz') {
      if (this.language === 'de') {
        this.state.page = <DatenschutzDE />;
      } else {
        this.state.page = <DatenschutzEN />;
      }
    } else if (label === 'impressum') {
      this.state.page = <Impressum />;
    } else if (label === 'denoising') {
      this.state.page = this.applicationArea.denoising;
    } else if (label === 'what_is_a_model') {
      this.state.page = <WhatIsAModel app={this} />;
    } else if (label === 'welcome') {
      this.state.page = <Welcome app={this}></Welcome>;
    } else if (label === 'news') {
      this.state.page = <NewsArea app={this}></NewsArea>;
    } else if (label === 'target') {
      if (this.language === 'de') {
        this.state.page = <TargetDE />;
      } else {
        this.state.page = <TargetEN />;
      }
    } else if (label === 'service') {
      this.state.page = <Service app={this} />;
    }

    console.log(this.state.page );

    this.label = label;
    this.setState({ ...this.state });
    this.t = (label) => {
      const out = this.translation[label];
      if (out === undefined) {
        return label;
      } else {
        return out;
      };
    }
  }

  render() {


    return (
      <div >
        <MainMenu app={this} />
        <div style={{ display: "flex-root", backgroundColor: "#EEF", width: "100%" }}>
          <div className="Row" >
            <div className="Cell" style={{ backgroundColor: "white", width: "100%" }}>
              {this.state.page}
            </div>
            <div className="Cell" style={{ backgroundColor: "#AFF", float: "right" }}>
              <div style={{ display: "inline-block", width: "200px", height: "728px" }}>
              </div>
              <div style={{ display: "none" }}>
                <ins className="adsbygoogle"
                  style={{ display: "inline-block", width: "200px", height: "728px" }}
                  data-ad-client="ca-pub-5694346183952215"
                  data-ad-slot="4829869557">
                </ins>
                <script>
                  (adsbygoogle = window.adsbygoogle || []).push({ });
                </script>
              </div>
            </div>
          </div>
        </div>
        <BaseArea app={this}></BaseArea>
        <CookieConsent buttonText={this.t('c:ok_button')}>{this.t('c:message')}
          <button className="square" onClick={ ()=>  this.showArea('datenschutz')}>
            {this.t('m:datenschutz')}
          </button>
        </CookieConsent>
      </div >
    );
  }
}



export default App;
