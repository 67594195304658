import { Menubar } from 'primereact/menubar';
import { Image } from 'primereact/image';
import React from 'react'
import { Dropdown } from 'primereact/dropdown';
import './App.css';

const languageSelectItems = [
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
];


function getItems(app, t){
    return  [
        {
            label: t('m:products'), icon: 'pi pi-fw pi-box',
            items: [
                { label: t('m:denoising'), 
                  command: () => { app.showArea('denoising') }
                /*, { label: 'Superresolution' }] */
                }
            ]
        },
        {
            label: t('m:services'), icon: 'pi pi-fw pi-users',
            command: () => { app.showArea('service') }
            /*
            items: [
                { label: 'Consulting' },
                { label: 'Hosting' },
                { label: 'Productintegration' },
            ]
            */

        },
        {
            label: 'Wiki', icon: 'pi pi-fw pi-book',
            items: [
                { label: t('m:what_is_a_model'), command: () => { app.showArea('what_is_a_model') } }
            ]
        },
        {
            label: t('m:news'), icon: 'pi pi-fw pi-megaphone', command: () => { app.showArea('news') }
        }
    ]
}

export class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.app.state.language
        };

     
        this.items = [];
    }


    setLanguage(lang) {
        this.props.app.changeLanguage(lang);
        this.setState({ language: lang });
    }

    showWelcome() {
        this.props.app.showArea('welcome')
    }

    render() {

        this.items = getItems(this.props.app, this.props.app.t);

        return (<Menubar className="sticky-top"
            model={this.items}
            start={
                < div >
                    <div style={{ float: "left", marginTop: "4px" }}><Image src="Logo48.png" alt="Logo" onClick={() => this.showWelcome()} /></div>
                    <div style={{ width: "250px" }} onClick={() => this.showWelcome()}>
                        <div style={{ fontSize: "xx-large", fontFamily: "cursive" }} onClick={() => this.showWelcome()}>Int4Me</div>
                        <div style={{ fontSize: "smaller" }} onClick={() => this.showWelcome()}>Intelligence for me!</div>
                    </div>
                </div>}
            end={<Dropdown value={this.state.language} options={languageSelectItems} onChange={(e) => this.setLanguage(e.value)}></Dropdown >}

        />);
        // end={<div><Button label="Login" icon="pi pi-power-off" /></div>}
    }
}