import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'



const TargetDE = () => {



    return (<div style={{ maxWidth: "900px" }}>
        <h1>Unsere Ziele</h1>

        <p>„Int 4 Me“ steht für „(künstliche) Intelligenz für mich“. Wir wollen, dass Du deine Anwendungen und
            Dienstleistungen mit Deep Learning Algorithmen erweiterst und dadurch erfolgreicher bist.</p>

        <p>Um dies zu erreichen, haben wir diese Internetpräsenz aufgebaut, damit Du einen Einblick in die Fähigkeiten unserer Netze bekommst. </p>

        <p>Die Produkte die hier gezeigt werden, sind nur der Anfang von einem großartigen Weg,
            um die Menschen mit einer richtigen künstlichen Intelligenz zu bereichern. </p>
    </div>
    )
}

export default TargetDE;