import React from 'react'

class Service extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.app.state.language,
        };

        this.de = {
            h1on: "Online Anwendungen",
            h2on: "Wir bauen ständig unsere Online Anwendungen aus, damit ihr unsere Produkte am einfachsten ausprobieren könnt.",
            p1on: "Mit unseren Online Anwendungen zeigen wir euch was unsere Modelle leisten. Wir wollen, dass " +
                "ihr ausgibig unsere Anwendungen testet und uns Feedback gibt, ob die Anwendung für euch nützlich sind. " +
                " " +
                "" +
                " ",
            p2on: "Neben den Online Anwendungen, die ohne Registrierung angeboten werden, werden wir zukünftig ein erweitertes " +
                "Online Angebot zur Verfügung stellen. Neben der Möglichkeit größere Bilder, Audiodatein usw. zu verarbeiten, " +
                "werden diese Aufgaben schneller verarbeitet. Auch soll die Parametriesierung der Aufgaben stärker vorhanden sein, " +
                "so dass Ihr noch bessere Ergebnisse erhält. " +
                "Wir planen, dass eine gewisse Anzahl von Aufgaben frei sind, und  " +
                "danach entweder das Angebot eingeschränkt oder mit einer Gebühr veranschlagt werden. " +
                "" +
                "",
            p3on: "Alle unsere Online Angebote werden für registrierte Kunden auch per Rest-API zur Verfügung stehen. " +
                " " +
                " " +
                "" +
                "",
            h1is: "Intelligence as a Service",
            h2is: "Wir bieten auch Schnittellen an damit ihr eure Cloud basierte Lösungen mit unseren Produkten verbinden könnt.",
            p1is: "",
            h1p: "Produktintegration",
            h2p: "Wir bieten auch eine Vor-Ort Integration von unseren Produkten an",
            p1p: "Häufig ist eine Online-Lösung nicht das preferierte Vorgehen für ein Unternehmen. Aus diese.",
            h1c: "Beratung",
            h2c: "Wir beraten auch gerne, ob und wie wir eines ihrer Probleme, mit unseren Modellen, lösen können."


        }

        this.en = {
            h1on: "Online Applications",
            h2on: "We are constantly expanding our online applications to make it the easiest way for you to try out our products.",
            p1on: "With our online applications we show you what our models can do. We want you to test our applications extensively " +
                "and give us feedback on whether the applications are useful to you. " +
                " " +
                "" +
                " ",
            p2on: "In addition to the online applications that are offered without registration, we will be making an expanded online "+
                "offer available in the future. In addition to being able to process larger images, audio files, etc., these tasks are "+
                "processed faster. The parameterization of the tasks should also be more present, so that you get even better results. "+
                "We plan to have a certain number of tasks free and then either limit the supply or charge a fee. " +
                "" +
                "",
            p3on: "All our online offers will also be available to registered customers via the Rest API. " +
                " " +
                " " +
                "" +
                "",
            h1is: "Intelligence as a Service",
            h2is: "We also offer interfaces so you can connect your cloud-based solutions to our products.",
            p1is: "",
            h1p: "Product Integration",
            h2p: "We also offer on-site integration of our products",
            p1p: "",
            h1c: "Consulting",
            h2c: "We are also happy to advise whether and how we can solve one of your problems with our models."
        }

    }

    render() {
        var lang = this.props.app.language;

        var t = (x) => {
            var out = this[lang][x];
            if (out === undefined) {
                return '*' + lang + '.' + x;
            }
            return out;
        }

        return (
            <div style={{ minWidth: "700px" }}>
                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                    <img src='OnlineThumbUpDim.png' style={{ maxWidth: "600px" }}></img>
                    <div style={{ width: "30%" }}></div>
                    <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{t('h1on')}</h2>
                        <p>{t('h2on')}</p>
                    </div>
                </div>
                <div style={{ margin: "40px" }}>
                    <p>{t('p1on')}</p>

                    <p>{t('p2on')}</p>
                    <p>{t('p3on')}</p>
                </div>
                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to left, white 00%, #125386 50%)" }}>
                    <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{t('h1is')}</h2>
                        <p>{t('h2is')}</p>
                    </div>
                    <div style={{ width: "30%" }}>

                    </div>
                    <img src='CloudFingerDim.png' style={{ maxWidth: "400px" }}></img>
                </div>

                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                    <img src='ProductIntegrationDim.png' style={{ maxWidth: "600px" }}></img>
                    <div style={{ width: "30%" }}></div>
                    <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{t('h1p')}</h2>
                        <p>{t('h2p')}</p>
                    </div>
                </div>

                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to left, white 00%, #125386 50%)" }}>
                    <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{t('h1c')}</h2>
                        <p>{t('h2c')}</p>
                    </div>
                    <div style={{ width: "30%" }}>

                    </div>
                    <img src='HandsTogetherDim.png' style={{ maxWidth: "400px" }}></img>
                </div>
            </div >
        )

    }
}

export default Service;