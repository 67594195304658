import axios from "axios";




export var server = "http://thomas-nn:8080";
var wsserver = "ws://localhost:8080";
export var userId = null;
export var webSocket = null;


console.log("wsserver:" + window.location.hostname);
export const getUserId = async () => {
    try {

        const response = await fetch('/host.txt');
        const hostdata = await response.json();
        console.log(hostdata);
    
        server = "http://" + hostdata.host;
        wsserver = "ws://" + hostdata.host;

        if (userId === null || userId === undefined || webSocket === null || webSocket.readyState === WebSocket.CLOSED) {
            const resp = await axios.get(server + '/user/unknownid');
            userId = resp.data;
            webSocket = new WebSocket(wsserver+`/process/` + userId);
            console.log("UserId:" + resp.data);
            console.log("update wsserver:" + wsserver);
        }
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
};