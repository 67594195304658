import React from 'react'



class NewsArea extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.app.state.language,
        };

        this.headline = { de: "Neuigkeiten", en: "News" };

        this.headlineSub = { de: "Es gibt immer was neues von Int4Me zu berichten.", en: "There is always something new to report from Int4Me." };

        this.newsHead = { de: "", en: "" };
        this.news = { de: "", en: "" };

        this.newsHead1 = { 
            de: "08.11.2022 Internetpräsenz gestartet", 
            en: "08. Nov 2022 Website launched" };
        this.news1 = { 
            de: "Int4Me hat die Internetpräsenz ins Netz gestellt und veröffentlichte das erste Online Angebot.", 
            en: "Int4Me put the website online and published the first online offer." };

    }

    render() {
        var lang = this.props.app.language;


        return (<div style={{ minWidth: "700px" }}>
            <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                <img src='Newspaper.png' style={{ width: "60%" }}></img>
                <div style={{ width: "40%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{this.headline[lang]}</h2>
                    <p>{this.headlineSub[lang]}</p>
                </div>
            </div>
            <div style={{ margin: "40px" }}>
                <h2>{this.newsHead1[lang]}</h2>
                <p>{this.news1[lang]}</p>
            </div>
            
        </div >
        )

    }
}
export default NewsArea;