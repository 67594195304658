import React from 'react'



class WhatIsAModel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.app.state.language,
        };

        this.de = {
            headline: "Was ist ein Model?",
            h2: "Ein Model ist die Menge aller Gewichte, Gewichtungsfunktionen und Verbindungen um eine Abbildung von Eingabe, Ausgabe und Status zu beschreiben.",
            h3: "Was ist ein Model?",
            p1: "Ein Model ist eine Abbildung von einer Eingabe und einem Status zu einer Ausgabe und einem " +
                "Nachfolgestatus. " +
                "Eingabemöglichkeiten könnten sein: ein Bild, Audiodatei, Tabellen oder andere Parameter sein. Der Status bildet die Vergangenheit " +
                "abstrakt ab. Die Ausgabe kann von der Struktur her wie die Eingabe aussehen. Es können aber auch Aktoren (Muskeln), " +
                "Klassenangaben ('Dies ist ein Hund') als Ausgabe dienen. Zusätzlich kann ein Nachfolgestatus angegeben werden, der für die " +
                "Eingabe im nächsten Schritt genutzt wird. Der Status ist also das 'Kurzzeitgedächtnis' des Models. ",
            p2: "Im Model sind die Gewichte, Gewichtsfunktionen und die Verbindung enthalten. Die Verbindungen beschreiben, wie " +
                "die Eingabe, Gewichte, Gewichtsfunktionen und Ausgabe miteinander verbunden sind. In der Regel gibt es klare " +
                "Strukturen, wie das Model aufgebaut ist. Weil die Verbindungen eine so große Rolle spielen, spricht man auch " +
                "oft von Netzen. " +
                "" +
                "",
            p3: "In vielen Modellen ist der Status gar nicht vorhanden, weil dieser nicht notwendig ist. " +
                "Zum Beispiel ist beim Entrauschen von Bildern ist die Eingabe das verrauschte Bild und die Ausgabe das " +
                "entrauschte Bild. Ein Status ist hier nicht notwendig. Sobald die Zeit eine Rolle spielt, wird der Status " +
                "notwendig. Dies ist immer dann der Fall wenn Abfolgen von Eingabewerten verarbeitet werden sollen." +
                "" +
                "",
            pic1: "Bild 1: Schema eines Model"
        }

        this.en = {
            headline: "What is a Model?",
            h2: 'A model is the set of all weights, weighting functions, and connections to describe an input-output mapping.',
            h3 :"What is a model?",
            p1:"A model is a mapping from an input and a status to an output and a successor status. Input options could be: an image, audio file, tables or other parameters. The status abstractly depicts the past. The output can look like the input in terms of structure. However, actors (muscles), class information ('This is a dog') can also serve as output. In addition, a successor status can be specified, which is used for the input in the next step. So the status is the 'short term memory' of the model.",
            p2:"The model contains the weights, weight functions and the connection. The connections describe how the input, weights, weight functions, and output are connected. As a rule, there are clear structures as to how the model is built. Because the connections play such an important role, one often speaks of networks.",
            p3:"In many models, the status does not exist at all because it is not necessary. For example, in image denoising, the input is the noisy image and the output is the denoised image. A status is not necessary here. When time matters, status becomes necessary. This is always the case when sequences of input values ​​are to be processed.",
            pic1:"Image 1: Schematic of a model"
        }

    }

    render() {
        var lang = this.props.app.language;

        var t = (x) => {
            var out = this[lang][x];
            if (out === undefined) {
                return '*' + lang + '.' + x;
            }
            return out;
        }

        return (<div style={{ minWidth: "700px" }}>
            <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                <img src='WikiWomanDim.png' style={{ maxWidth: "600px" }}></img>
                <div style={{ width: "30%" }}></div>
                <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{t('headline')}</h2>
                    <p>{t('h2')}</p>
                </div>
            </div>
            <div style={{ margin: "40px" }}>
                <h2>{t('h3')}</h2>
                <p>{t('p1')}</p>
                <figure>
                    <img src='Model.svg'></img>
                    <figcaption>{t('pic1')}</figcaption>
                </figure>

                <p>{t('p2')}</p>
                <p>{t('p3')}</p>
            </div>

        </div >
        )

    }
}
export default WhatIsAModel;