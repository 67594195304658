import React from 'react'
import { Button } from 'primereact/button'
import UploadService from "../services/file-upload.service";
import { Image } from 'primereact/image';
import MyProgressBar from '../component/MyProgressBar';
import { webSocket, server, userId, getUserId } from "../environment";
import { Galleria } from 'primereact/galleria';

export default class DenoisingArea extends React.Component {

    constructor(props) {
        super(props);

        this.props.model.myself = this;

        this.label = {
            ready: { de: "Fertig: ", en: "Ready: " },
            progress: { de: "In Bearbeitung: ", en: "Running: " },
            denoising: { de: "Entrauschen", en: "Denoising" },
            subtitle: {
                de: "Wir entrauschen Deine Lieblingsbilder. Bearbeite diese hier bei Int4Me und freue Dich über das Ergebnis.",
                en: "We denoise your favorite pictures. Edit them here at Int4Me and enjoy the result."
            },
            infoText: {
                de: "Laden ein Bild hoch, welches entrauscht werden soll. Beachte, dass das Bild nicht größer als 1024x1024 Pixel ist. Es werden nur JPEG und PNG Formate unterstützt.",
                en: "Upload an image that should be denoised. Note that the image is no larger than 1024x1024 pixels. Only JPEG and PNG formats are supported."
            },
            uploadError: {
                de: "Das Bild konnte nicht hochgeladen werden!",
                en: "Could not upload the image!"
            },
            uploadSuccess: {
                de: "Das Bild ist hochgeladen.",
                en: "Ihe image is uploaded!"
            },
            taskStateLabel: {
                de: "Bearbeitungsstatus: ",
                en: "Task State: "
            },
            waitingForUpload: {
                de: "Warte zum Hochladen.",
                en: "Waiting for upload."
            },
            processEnd: {
                de: "Entrauschen beendet.",
                en: "Denoising finish."
            },
            processFastEnd: {
                de: "Fertig.",
                en: "Done"
            },
            processing: {
                de: "Bearbeitung des Bildes.",
                en: "Processing of image."
            },
            successUpload: {
                de: "Bild hochgeladen. Verarbeitung in Vorbereitung.",
                en: "Image uploaded. Processing in preperation."
            }, // QUEUE_FULL, INVALID_FILE,  WRONG_IMAGE_FORMAT, IMAGE_TO_LARGE, IMAGE_TO_SMALL
            failUpload_INVALID_FILE: {
                de: "Bild hochladen ist fehlgeschlagen.",
                en: "Image upload is failed."
            },
            failUpload_QUEUE_FULL: {
                de: "Warteschlange ist zu lang. Probiere es später nochmal.",
                en: "The task queue is full. Try again later."
            },
            failUpload_WRONG_IMAGE_FORMAT: {
                de: "Nur Bilder in JPEG und PNG Format werden unterstützt.",
                en: "Only images in JPEG and PNG format are supported."
            },
            failUpload_IMAGE_TO_LARGE: {
                de: "Bilder dürfen maximal 1024x1024 Pixel groß sein. Diese Einschränkung gilt nur für die Community-User. ",
                en: "Images must not be larger than 1024x1024 pixels. This restriction only applies to the community users."
            },
            failUpload_IMAGE_TO_SMALL: {
                de: "Bilder müssel mindestens 64x64 Pixel groß sein.",
                en: "Images must be at least 64x64 pixels in size."
            },
            initImageLabel: {
                de: "Bild zum Entrauschen",
                en: "Image to denoising."
            },
            fileSelected: {
                de: "Bild ausgewählt",
                en: "Image selected"
            },
            queuePosition: {
                de: "Noch ### Aufträge vor dir.",
                en: "### more orders ahead of you."
            },
            addNoise: {
                de: "Rauschen hinzufügen",
                en: "Add Noise"
            },
            scaling: {
                de: "Bild skalieren (max. 1024x1024)",
                en: "Scale Image (max. 1024x1024)"
            }


        }

        this.t = (x) => this.label[x][this.props.app.language];

        this.selectFile = this.selectFile.bind(this);
        this.upload = this.upload.bind(this);

        this.taskId = null;

        this.state = {
            currentFile: undefined,
            previewImage: undefined,
            progress: 0,
            progressMessage: "",
            processName: "",
            message: this.t('waitingForUpload'),
            messageColor: "black",
            imageResults: [],
            language: this.props.app.state.language,
        };

        if (webSocket !== null) {

            webSocket.onmessage = (event) => {
                const data = JSON.parse(event.data);

                if (data.message === 'queuePosition') {
                    this.state.progressMessage = "";
                    this.state.progress = 0;
                    this.state.message = this.t('queuePosition').replace("###", data.vals[0]);
                    this.state.messageColor = "darkred";
                } else if (data.message === 'progress') {
                    this.state.progress = data.vals[0];
                    this.state.progressMessage = this.t('processing');
                    this.state.message = this.t('processing');
                    this.state.messageColor = "darkred";
                } else if (data.message === 'ready') {

                    if (data.process === 'denoising') {
                        this.state.message = this.t('processEnd');
                        this.state.progressMessage = this.t('processEnd');
                    } else {
                        this.state.message = this.t('processFastEnd');
                        this.state.progressMessage = this.t('processFastEnd');
                    }
                    fetch(server + "/img/resultFile/" + data.taskId + "/" + data.vals[0], {
                        headers: {
                            'userid': userId
                        }
                    })
                        .then(response => response.blob())
                        .then(imageBlob => {
                            // Then create a local URL for that image and print it 
                            this.state.messageColor = "darkgreen";
                            const imageObjectURL = URL.createObjectURL(imageBlob);
                            this.state.previewImage = imageObjectURL;
                            this.state.currentFile = imageBlob;
                            if (data.process === 'denoising')
                                this.state.imageResults = [... this.state.imageResults, imageObjectURL];
                            this.setState({ ... this.state })
                        });
                }
                this.setState({ ... this.state })
            }
        }
    }



    selectFile(event) {
        this.setState({
            ... this.state,
            currentFile: event.target.files[0],
            currentFileName: event.target.files[0].name,
            previewImage: URL.createObjectURL(event.target.files[0]),
            progress: 0,
            message: this.t('fileSelected'),
            messageColor: "black"
        });
    }


    upload = (processName) => {
        this.setState({
            ...this.state,
            progress: 0,
        });

        UploadService.upload(this.state.currentFile, this.state.currentFileName, (event) => {
        }, processName)
            .then((response) => {
                if (response.data.error === 'OK') {
                    this.taskId = response.data.taskId;
                    this.state.message = this.t('successUpload');
                    this.state.messageColor = "darkred";
                } else if (response.data.error === 'IGNORE') {
                    this.taskId = response.data.taskId;
                } else {
                    this.state.message = this.t('failUpload_' + response.data.message);
                    this.state.messageColor = "red";
                    this.state.progress = 0;
                }

                this.setState({ ... this.state })
            })
            .catch((err) => {
                console.log("err:" + err)
                this.setState({
                    ... this.state,
                    progress: 0,
                    message: this.t('uploadError'),
                    currentFile: undefined,
                    messageColor: "red"
                });
            });
    }


    render() {

        var lang = this.props.app.language;

        const t = (x) => this.label[x][lang];

        const {
            currentFile,
            previewImage,
            progress,
            message,
            progressMessage,
            imageResults,
            messageColor,
        } = this.state;

        const itemTemplate = (item) => {
            return <img src={item} style={{ width: '100%', display: 'block' }} />;
        }

        const thumbnailTemplate = (item) => {
            return <img src={item} style={{ width: '100px', display: 'block' }} />;
        }


        if (webSocket === null) {
            return (<><h1>The service is not operational.</h1><h2>Please try again later.</h2></>)
        }


        return (


            <div>
                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                    <img src='Newspaper.png' style={{ width: "60%" }}></img>
                    <div style={{ width: "40%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>{t('denoising')}</h2>
                        <p>{t('subtitle')}</p>
                    </div>
                </div>

                <div style={{ width: "750px", margin: "10px" }} >{t('infoText')}</div>
                <div>
                    <div style={{ width: "750px", height: "60px", margin: "10px" }}>

                        <input className="p-button p-component p-2" text="Alternativetext" type="file" accept="image/png, image/jpeg" onChange={this.selectFile} />
                        <Image src="BlueArrow.png" />
                        <Button
                            style={{ height: "43px", width: "200px" }}
                            className="btn btn-default p-2"
                            disabled={!currentFile}
                            onClick={() => this.upload("denoising")}
                        >
                            {t('denoising')}
                        </Button>
                    </div>
                    <div style={{ width: "750px", height: "60px", margin: "10px" }}>
                        <Button
                            style={{ height: "43px", width: "200px" }}
                            className="btn btn-default p-2"
                            disabled={!currentFile}
                            onClick={() => this.upload("noising")}
                        >
                            {t('addNoise')}
                        </Button>

                        <Button
                            style={{ marginLeft: "30px", height: "43px", width: "200px" }}
                            className="btn btn-default p-2"
                            disabled={!currentFile}
                            onClick={() => this.upload("scaling")}
                        >
                            {t('scaling')}
                        </Button>


                    </div>

                    {message && (
                        <div style={{ color: messageColor, margin: "10px" }} className="alert alert-secondary mt-3" role="alert">
                            {t('taskStateLabel')}{message}
                        </div>
                    )}

                    {currentFile && (
                        <div className="progress my-3">
                            <MyProgressBar progress={progress * 0.1} message={progressMessage} />
                        </div>
                    )}



                    <div style={{ border: "2px", borderStyle: "inset", margin: "10px" }}>
                        {previewImage && (
                            <div style={{ border: "2px", borderStyle: "inset", margin: "10px" }}>
                                <img style={{ width: "700px" }} className="preview" src={previewImage} alt="" />
                            </div>
                        )}
                        {!previewImage && (
                            <div style={{ background: "aliceblue", border: "1px inset", width: "750px", height: "500px", borderStyle: "inset", margin: "10px" }}>
                                {t('initImageLabel')}
                            </div>
                        )}
                    </div>

                    <Galleria value={imageResults} item={itemTemplate} thumbnail={thumbnailTemplate}></Galleria>


                </div>

            </div>
        )
    }
}

/*
                        <Button
                            style={{ height: "43px", width: "200px" }}
                            className="btn btn-default p-2"
                            disabled={!currentFile}
                            onClick={this.noising}
                        >
                            "Verrauschen"
                        </Button>
                        */