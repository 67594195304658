import { Menubar } from 'primereact/menubar';
import React from 'react'

export class BaseArea extends React.Component {

   constructor(prop) {
      super();
      this.state = {
      };

      this.items = [
      ];



      this.getItems = (app, t) => {
         return (
            [
               {
                  label: 'Impressum',
                  icon: 'pi pi-fw pi-file',
                  command: () => { app.showArea('impressum') }
               },
               /*
               {
                  label:'AGB',
                  icon:'pi pi-fw pi-file',
                  command:()=>{ console.log(app)}
               },*/
               {
                  label: t('m:datenschutz'),
                  icon: 'pi pi-fw pi-file',
                  command: () => { app.showArea('datenschutz') }
               }
            ])
      }
   }
   render() {
      this.items = this.getItems(this.props.app, this.props.app.t);
      return (<Menubar
         model={this.items}
      />)
   }
}