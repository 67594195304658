import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'



class Welcome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.app.state.language,
        };


    }




    render() {
        if (this.props.app.language === 'de') {

            return (<div style={{ minWidth: "700px" }}>
                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                    <img alt="" src='ToyDim.png' style={{ width: "60%" }}></img>
                    <div style={{ width: "40%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>Die Seite für Deep Learning Lösungen</h2>
                        <p>Wir bieten einen Einblick in einer Auswahl von Tools die ihr ausprobieren könnt. </p>
                    </div>
                </div>
                <div style={{ display: "inline-flex" }}>
                    <div style={{ width: "50%" }}>
                        <div style={{ margin: "40px" }}>
                            <h1>Probieren geht über studieren!</h1>
                            <p>  Dies könnte der Leitspruch für Deep Learning sein. <br></br>Um großartige Lösungen mit Deep Learning
                                entwickeln zu können, benötigt man viel Erfahrung.
                                Auf unserer Internetpräsenz Int4Me zeigen wir euch ein paar Beispielsanwendungen. Damit könnt ihr
                                unsere Deep Learning Produkte ausprobieren. </p>
                            <p>Spielt etwas mit den Parametern, bearbeitet eure eigenen Bilder oder andere Dinge. Je mehr ihr mit Deep Learning und unseren
                                Tools von Int4Me spielt, umso mehr erkennt ihr, wie Deep Learning Algorithmen funktionieren.
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "50%", backgroundColor: "#e7fffb" }}>
                        <div style={{ margin: "40px" }}>
                            <h1>Wer Wir Sind</h1>
                            <p>Int4Me ist ein Tech-Startup welches Deep Learning Produkte und Verfahren entwickelt.</p>
                            <p>Nach jahrelanger Grundlagenforschung sind wir nun bereit, unsere eigenen Deep Learning Lösungen zu entwickeln und zu vermarkten. </p>
                            <p>Die Entwicklung und Erweiterung unserer Deep-Learning-Produkte ist ein ständig währender Prozeß.
                                Neben den offensichtlich praktischen
                                Anwendungen, wie zum Beispiel 'Entrauschen von Bildern',
                                haben wir auch Anwendungen, die eher Spaß machen oder Prinzipien
                                im Fokus haben.
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ color: "#FFF", height: "300px", backgroundImage: "linear-gradient(to bottom, white 0%, #125386 50%)" }}>
                    <img alt="" src='EyeColorDim.png' style={{ height: "150px", width: "100%" }}></img>
                    <div style={{ margin: "20px", width: "800px" }}><h2 style={{ color: "#0DD" }}>Was ist Deep Learning?</h2>
                        <p>Deep Learning ist kein wohl definierter Begriff. Lern-Algorithmen werden in der Regel als Netze beschrieben,
                            die in Schichten aufgebaut sind.
                            Wenn das Netz mit <b>vielen</b> Schichten aufgebaut wurde, so spricht man von Deep Learnning.
                            Dabei handelt es sich nicht um eine Qualitäts-, sondern eher um eine Quantitätsangabe.</p>


                    </div>


                </div>
                <div style={{ maxWidth: "700px", margin: "10px", paddingLeft: "100px" }}>
                    <h1>Wie funktioniert Deep Learning?</h1>
                    <p style={{ margin: "10px" }}>Bei Deep Learning, und dadurch auch unsere Produkte von Int4Me, werden aus Beispielsdaten
                        eine Abbildung geneneriert. Es werden zum Beispiel verrauschte Bilder als Eingabe und unverrauschte Bilder als Zielausgabe
                        genommen. Mit einem Abbildungsmodell und vielen Variablen wird dann versucht, die verrauschten in unverrauschte Bilder zu
                        umzurechnen, und zwar möglichst für alle Bilder die es normalerweise gibt. </p>
                    <p style={{ margin: "10px" }}>Es gibt auch Grenzen dieser Methode. So kann die Menge der Lerndaten zu klein oder zu einseitig sein.
                        Auch ist die Ausgabe in der Regel nicht perfekt. Zum Beispiel kann eine Blume als Haus erkannt werden oder das entrauschte Bild hat bunte Flecken.
                        Wenn also Deep Learning in Maschinen verwendet werden, müssen die Deep Learning Modelle sehr gut getestet werden und dann ist man sich
                        immer noch nicht sicher, ob alles 100% richtig läuft. </p>
                </div>
                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to left, white 00%, #125386 50%)" }}>
                    <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>Was macht uns besonders?</h2>
                        <p>Wir haben Deep Learning von Grund auf neu implementiert und neuartige Algorithmen entwickelt. </p>
                    </div>
                    <div style={{ width: "30%" }}>

                    </div>
                    <img alt="" src='ThumbDim.png' style={{ maxWidth: "400px" }}></img>
                </div>
                <table className="welcome"><tbody>
                    <tr>
                        <th className="welcome" ><div className="welcome">Eigene Algorithen</div></th>
                        <th className="welcome"><div className="welcome">Methoden</div></th>
                        <th className="welcome"><div className="welcome">Implementierung</div></th></tr>

                    <tr>
                        <td className="welcome">Im Großen und Ganzen ist der Grundalgorithmus immer gleich. Wir haben aber einige Besonderheiten
                            entwickelt. Wir lernen schneller bei gleichen Rechenaufwand und unsere Netze sind sehr stabil. </td>
                        <td className="welcome">Wir haben einige Methoden entwickelt die einige grundlegende Probleme lösen. Und wir sind bereit
                            diese in zukünftige Produkte einzubauen.</td>
                        <td className="welcome">Weil unsere Algorithmen mit einer anderen Sichtweise entwickelt werden, haben wir die Software vollständig neu entwickeln müssen.
                            Mit den am Markt befindlichen Standardimplentierungen konnten wir unsere Ideen nicht verwirklichen.</td></tr>
                </tbody></table>
                <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                    <img alt="" src='HelpHand.png' style={{ maxWidth: "600px" }}></img>
                    <div style={{ width: "30%" }}></div>
                    <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>Wie können wir euch helfen?</h2>
                        <p>Neben unseren Online Angeboten von Int4Me können wir euch auch im Thema Deep Learning beraten.</p>
                    </div>
                </div>
                <div style={{ display: "inline-flex" }}>
                    <div style={{ width: "33%", backgroundColor: "#e7fffb" }}>
                        <div style={{ margin: "20px", }}>
                            <h2 ><li style={{ padding: "10px" }}>Online Angebote</li></h2>
                            <p>Wir bauen unser Online Angebot kontinuierlich aus, so dass ihr die aktuellen Lösungen
                                und Modelle spielerisch erfahren könnt. </p>
                            <p>Neben dem Bereich der praktischen haben wir auch spielerische Angebote,
                                die erkennen lassen wie bestimmte Modelle funktionieren.
                                So kann man zum Beispiel erleben wie ein Autoencoder Bilder komprimiert oder
                                erleben, wie Übergänge von einem Bild zu einem anderen Bild aussehen.
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "34%", backgroundColor: "#d3fff7" }}>
                        <div style={{ margin: "20px", }}>
                            <h2 ><li style={{ padding: "10px" }}>API-Schnittstelle</li></h2>
                            <p style={{ margin: "10px" }}>Zu allen Modellen bieten wir auch eine API-Schnittstelle an.
                                Damit sollte es möglich sein, dass
                                Ihr unsere Modelle in eure Produkte, wie zum Beispiel eine eigene Internetpräsenz, einbaut.
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "33%", backgroundColor: "#adfff0" }}>
                        <div style={{ margin: "20px", }}>
                            <h2 ><li style={{ padding: "10px" }}>Beratung</li></h2>
                            <p style={{ margin: "10px" }}>Es kann durchaus sein, dass kein passendes Model für euer Produkt oder Problem.
                                Wir beraten gerne, und prüfen, wie wir angepasste Deep Learning Modelle für eure Bedürfnisse erstellen können.
                                Wir lieben die Herausforderungen die sich hieraus ergeben.
                            </p>
                        </div>
                    </div>
                </div>
            </div >
            )
        }
        return (<div style={{ minWidth: "700px" }}>
            <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                <img alt="" src='ToyDim.png' style={{ width: "60%" }}></img>
                <div style={{ width: "40%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>The page for deep learning solutions</h2>
                    <p>We offer a glimpse of a selection of tools you can try. </p>
                </div>
            </div>
            <div style={{ display: "inline-flex" }}>
                <div style={{ width: "50%" }}>
                    <div style={{ margin: "40px" }}>
                        <h1>Testing is above studying!</h1>
                        <p> This could be the motto for deep learning.<br></br>To be able to develop great solutions with
                            deep learning, you need a lot of experience. On our website Int4Me we show you a few example
                            applications. With this you can try our Deep Learning products. </p>
                        <p>Play with the parameters, edit your own images or other things. The more you play with
                            deep learning and our tools from Int4Me, the more you realize how deep learning
                            algorithms work.
                        </p>
                    </div>
                </div>
                <div style={{ width: "50%", backgroundColor: "#e7fffb" }}>
                    <div style={{ margin: "40px" }}>
                        <h1>Who we are</h1>
                        <p>Int4Me is a tech startup that develops deep learning products and processes.</p>
                        <p>After years of basic research, we are now ready to develop and market our own deep learning solutions.</p>
                        <p>The development and expansion of our deep learning products is an ongoing process. In addition to the
                            obviously practical applications, such as 'denoising images', we also have applications that are more
                            fun or focus on principles.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ color: "#FFF", height: "300px", backgroundImage: "linear-gradient(to bottom, white 0%, #125386 50%)" }}>
                <img alt="" src='EyeColorDim.png' style={{ height: "150px", width: "100%" }}></img>
                <div style={{ margin: "20px", width: "800px" }}><h2 style={{ color: "#0DD" }}>What is Deep Learning?</h2>
                    <p>Deep learning is not a well-defined term. Learning algorithms are usually described as networks that
                       are built up in layers. If the network has been built up with <b>many</b> layers, this is called deep
                       learning. This is not an indication of quality, but rather of quantity.</p>


                </div>


            </div>
            <div style={{ maxWidth: "700px", margin: "10px", paddingLeft: "100px" }}>
                <h1>How does deep learning work?</h1>
                <p style={{ margin: "10px" }}>With deep learning, and thus also our products from Int4Me, a figure is generated 
                    from sample data. For example, take noisy images as input and clean images as target output. With an imaging 
                    model and many variables, an attempt is then made to convert the noisy images into noise-free images, if 
                    possible for all images that normally exist. </p>
                <p style={{ margin: "10px" }}>There are also limitations of this method. The amount of learning data can be too 
                    small or too one-sided. Also, the output is usually not perfect. For example, a flower can be recognized as 
                    a house, or the denoised image has colorful spots. So if deep learning is used in machines, the deep learning 
                    models have to be tested very well and then you are still not sure if everything is 100% right. </p>
            </div>
            <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to left, white 00%, #125386 50%)" }}>
                <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>What makes us special?</h2>
                    <p>We implemented deep learning from scratch and developed novel algorithms. </p>
                </div>
                <div style={{ width: "30%" }}>

                </div>
                <img alt="" src='ThumbDim.png' style={{ maxWidth: "400px" }}></img>
            </div>
            <table className="welcome"><tbody>
                <tr>
                    <th className="welcome" ><div className="welcome">Own Algorithms</div></th>
                    <th className="welcome"><div className="welcome">Methods</div></th>
                    <th className="welcome"><div className="welcome">Implementation</div></th></tr>

                <tr>
                    <td className="welcome">By and large, the basic algorithm is always the same. However, we have developed some special 
                        features. We learn faster with the same computing effort and our networks are very stable. </td>
                    <td className="welcome">We have developed some methods that solve some basic problems. And we are ready to build 
                        them into future products.</td>
                    <td className="welcome">Because our algorithms are developed with a different perspective, we had to develop the 
                        software from scratch. We could not realize our ideas with the standard implementations available on the market.</td></tr>
            </tbody></table>
            <div style={{ color: "#FFF", height: "300px", display: "flex", backgroundImage: "linear-gradient(to right, white 00%, #125386 50%)" }}>
                <img alt="" src='HelpHand.png' style={{ maxWidth: "600px" }}></img>
                <div style={{ width: "30%" }}></div>
                <div style={{ width: "70%", margin: "40px" }}><h2 style={{ color: "#0DD" }}>How can we help you?</h2>
                    <p>In addition to our online offers from Int4Me, we can also advise you on the subject of deep learning.</p>
                </div>
            </div>
            <div style={{ display: "inline-flex" }}>
                <div style={{ width: "33%", backgroundColor: "#e7fffb" }}>
                    <div style={{ margin: "20px", }}>
                        <h2 ><li style={{ padding: "10px" }}>Online Offers</li></h2>
                        <p>We are continuously expanding our online offer so that you 
                            can experience the current solutions and models in a playful way. </p>
                        <p>In addition to the practical area, we also have playful offers that show how certain models 
                            work. For example, you can experience how an autoencoder compresses images or how transitions 
                            from one image to another look like.
                        </p>
                    </div>
                </div>
                <div style={{ width: "34%", backgroundColor: "#d3fff7" }}>
                    <div style={{ margin: "20px", }}>
                        <h2 ><li style={{ padding: "10px" }}>API Interface</li></h2>
                        <p style={{ margin: "10px" }}>We also offer an API interface for all models. This should make it possible 
                            for you to incorporate our models into your products, such as your own website.
                        </p>
                    </div>
                </div>
                <div style={{ width: "33%", backgroundColor: "#adfff0" }}>
                    <div style={{ margin: "20px", }}>
                        <h2 ><li style={{ padding: "10px" }}>Consulting</li></h2>
                        <p style={{ margin: "10px" }}>It may well be that there is no suitable model for your product or problem. 
                            We are happy to advise and check how we can create customized deep learning models for your needs. 
                            We love the challenges that result from this.
                        </p>
                    </div>
                </div>
            </div>
        </div >
        )
    }
}
export default Welcome;