import React, { Component } from "react";
import { ProgressBar } from 'primereact/progressbar';

export default class MyProgressBar extends Component {
    constructor(props) {
        super(props);
    }



    render() {
        const p = this.props.progress;
        const msg = this.props.message;
        return (
            <div role="progressbar" className="p-progressbar p-component p-progressbar-determinate" aria-valuemin="0" aria-valuenow="0" aria-valuemax="1000">
                <div className="p-progressbar-value" style={{ width: p+ "%", display: "block" }}></div>
                <div className="p-progressbar-label">{msg} {p.toFixed(1) }%</div>
            </div>
        );
    }
}
