import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'



const TargetEN = () => {



    return (<div style={{ maxWidth: "900px" }}>
        <h1>Our goals</h1>
        <p>"Int 4 Me" stands for "(artificial) intelligence for me". We want you to expand your applications and
            services with deep learning algorithms and be more successful as a result.</p>

        <p>In order to achieve this, we have set up this website so that you can get an insight into the capabilities of our networks.</p>

        <p>The products shown here are just the beginning of a great way to enrich people with real artificial intelligence.</p>
        {navigator.languages}
    </div>
    )
}

export default TargetEN;